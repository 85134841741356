import React from 'react'
import { Spinner, Progress } from 'reactstrap'

export class Loading extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: <></>
        }

        this.shortTimer = ""
        this.longTimer = ""
    }

    componentDidUpdate(prevProps) {
        if (this.props.show !== prevProps.show) {
            if (this.props.show) {
                this.shortTimer = setTimeout(() => {
                    this.setState({ loading: ShortLoading() })

                    this.longTimer = setTimeout(() => {
                        this.setState({ loading: LongLoading() })
                    }, 4500)
                }, 500)
            }
            else {
                clearTimeout(this.shortTimer)
                clearTimeout(this.longTimer)
                this.setState({ loading: <></> })
            }
        }
    }

    render() {
        return (
            this.state.loading
        )
    }
}

function ShortLoading() {
    return (
        <>
            <div className="modal" style={{ display: "block", height: 55 + "px", zIndex: 2000 }}>
                <div style={{ maxWidth: 30 + "px", margin: "1.75rem auto", marginTop: "0.5rem" }} >
                    <Spinner color="warning" />
                </div>
            </div>
        </>
    )
}

function LongLoading() {
    return (
        <>
            <div className="modal" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false" style={{ display: "block", zIndex: 2000 }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Aguarde...</h4>
                        </div>
                        <div className="modal-body">
                            <Progress animated color="info" value="100" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    )
}