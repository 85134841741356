import React from 'react'
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Loading, Toast, ToastTypes } from "../global/Utils"
import { faFileCsv, faFilter } from '@fortawesome/free-solid-svg-icons'

class Project extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                subTotal: [],
                pagination: true,
                actions: []
            },
            search: [],
            groupBy: 0
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleExport = this.handleExport.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        Promise.all([
            fetch("/api/capex/actualyears", requestOptions),
            fetch("/api/capex/projects", requestOptions),
            fetch("/api/reporting/sites", requestOptions)
        ])
            .then(allResponses => {
                if (allResponses[0].status === 200 && allResponses[1].status === 200 && allResponses[2].status === 200) {
                    Promise.all([allResponses[0].json(), allResponses[1].json(), allResponses[2].json()])
                        .then(allJson => {
                            let years, months, projects, sites, groupBy

                            years = allJson[0].map((x, index) => { return { value: x, text: x, selected: index === 0 } })
                            let month = (new Date()).getMonth()
                            months = [
                                { value: 1, text: "janeiro", selected: month === 1 }, { value: 2, text: "fevereiro", selected: month === 2 }, { value: 3, text: "março", selected: month === 3 },
                                { value: 4, text: "abril", selected: month === 4 }, { value: 5, text: "maio", selected: month === 5 }, { value: 6, text: "junho", selected: month === 6 },
                                { value: 7, text: "julho", selected: month === 7 }, { value: 8, text: "agosto", selected: month === 8 }, { value: 9, text: "setembro", selected: month === 9 },
                                { value: 10, text: "outubro", selected: month === 10 }, { value: 11, text: "novembro", selected: month === 11 }, { value: 12, text: "dezembro", selected: month === 0 }
                            ]

                            sites = allJson[2].map(x => { return { value: x.key, text: x.value, selected: false } })
                            sites.splice(0, 0, { value: -1, text: "Todos", selected: true })

                            projects = allJson[1].map(x => { return { value: x.key, text: x.value, selected: false } })
                            projects.splice(0, 0, { value: -1, text: "Todos", selected: true })

                            groupBy = [{ value: 0, text: "SiteNumber", selected: true }, { value: 1, text: "Projeto", selected: false }]

                            let query = "?year=" + years.find(f => f.selected).value + "&start=1&end=" + months.find(f => f.selected).value + "&group=" + groupBy.find(f => f.selected).value

                            fetch("/api/reporting/project" + query, requestOptions)
                                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                                .then(json => {
                                    this.setState({
                                        search: json,
                                        table: {
                                            title: "Projetos",
                                            columns: {
                                                title: ["Site", "Projetado", "Atual", "Balanço"],
                                                width: [70, 10, 10, 10],
                                                align: ["left", "right", "right", "right"],
                                                search: [true, true, true, true]
                                            },
                                            rows: this.buildGridRows(json),
                                            subTotal: [false, true, true, true],
                                            pagination: true,
                                            actions: [
                                                { action: "filter", icon: faFilter },
                                                { action: () => this.handleExport(), icon: faFileCsv }
                                            ],
                                            filters: [
                                                { id: "lstYears", type: "list", data: years, label: "Ano", multiple: false },
                                                {
                                                    id: "lstMonthsStart", type: "list", data: months.map(m => { return { ...m, selected: m.value === 1 } }), label: "Mês Inicio", multiple: false
                                                },
                                                { id: "lstMonthsEnd", type: "list", data: months, label: "Mês Fim", multiple: false },
                                                { id: "lstGroupBy", type: "list", data: groupBy, label: "Agrupar por", multiple: false },
                                                { id: "lstSites", type: "list", data: sites, label: "Sites", multiple: false },
                                                { id: "lstProjects", type: "list", data: projects, label: "Projetos", multiple: false }
                                            ]
                                        }
                                    })

                                    this.props.dispatch(Loading(false))
                                })
                                .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
                        })
                        .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
                }
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
    }

    buildGridRows(json) {
        return json.map((row, index) => {
            return {
                id: index,
                columns: [
                    { column: row.groupBy },
                    { column: parseFloat(row.projection).toLocaleString() },
                    { column: parseFloat(row.actual).toLocaleString() },
                    { column: parseFloat(row.balance).toLocaleString() }
                ]
            }
        })
    }

    handleFilter(data) {
        
        let capexYear = parseInt(data[0])
        let capexStartMonth = parseInt(data[1])
        let capexEndMonth = parseInt(data[2])
        let groupBy = data[3]
        let siteNumber = data[4]
        let projectId = data[5]

        let query = "?year=" + capexYear + "&start=" + capexStartMonth + "&end=" + capexEndMonth + "&group=" + groupBy
        if (parseInt(siteNumber) !== -1)
            query += "&sitenumber=" + siteNumber
        if (parseInt(projectId) !== -1)
            query += "&projectid=" + projectId

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/api/reporting/project" + query, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    search: json,
                    groupBy: groupBy,
                    table: {
                        ...this.state.table,
                        rows: this.buildGridRows(json),
                        columns: {
                            ...this.state.table.columns,
                            title: groupBy === 0 ?
                                ["Site", "Projetado", "Atual", "Balanço"]
                                :
                                ["Projeto", "Projetado", "Atual", "Balanço"]
                        }
                    }
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => {
                this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false))
            })
    }

    handleExport() {

        if (this.state.search.length === 0)
            return

        let csv = "\uFEFF"

        if (this.state.groupBy === 0)
            csv += "Site,Projetado,Atual,Balanço\r\n"
        else
            csv += "Projeto,Projetado,Atual,Balanço\r\n"

        csv += convertToCSV(this.state.search.map(m => "\"" + m.groupBy + "\",\"" + parseFloat(m.projection).toLocaleString() + "\",\"" +
            parseFloat(m.actual).toLocaleString() + "\",\"" + parseFloat(m.balance).toLocaleString() + "\""))

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        var link = document.createElement("a")

        if (link.download !== undefined) {

            link.setAttribute("href", URL.createObjectURL(blob))
            link.setAttribute("download", "capex_reporting_project.csv")
            link.style.visibility = 'hidden'

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }

    render() {
        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} subTotal={this.state.table.subTotal} />
            </>
        )
    }
}

const convertToCSV = objArray => {
    let str = ''

    objArray.forEach(item => { str += item + "\r\n" })

    return str;
}

const mapStateToProps = state => {
    return {
        access_token: state.access_token
    }
}

export default connect(mapStateToProps)(Project)
