import React from 'react'
import { connect } from "react-redux"
import { Toast, ToastTypes, Loading } from "../global/Utils"
import { faEdit, faFilter, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Row, Col, Input, Label } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import McdTable from "../global/Table"

class Categories extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            categories: [],
            modal: {
                isOpen: false,
                categoryId: 0,
                category: 0,
                subCategory: 0,
                categoryName: ""
            }
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleSaveCategory = this.handleSaveCategory.bind(this)
        this.handleDeleteCategory = this.handleDeleteCategory.bind(this)
    }

    toggle = () => {
        this.setState(prevState => { return { modal: { ...prevState.modal, isOpen: !prevState.modal.isOpen } } })
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/maintenance/categories", requestOptions).then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                let lstCategories = []
                lstCategories.push({ value: -1, text: "Todas", selected: true })
                lstCategories.push(...json.filter(f => f.subCategory === 0).map(r => { return { value: r.category, text: r.category + " - " + r.categoryName, selected: false } }))

                this.setState({
                    categories: json,
                    table: {
                        title: "Categorias",
                        columns: {
                            title: ["Categoria", "Sub-Categoria", "Nome", ""],
                            width: [15, 15, 60, 10],
                            align: ["center", "center", "left", "right"],
                            search: [true, true, true, false]
                        },
                        rows: json.map(row => {
                            return {
                                id: row.categoryId,
                                columns: [
                                    { column: row.category },
                                    { column: row.subCategory },
                                    { column: row.categoryName }
                                ],
                                actions: [
                                    { column: faEdit, action: () => this.handleEditCategory(row.categoryId) }
                                ]
                            }
                        }),
                        pagination: true,
                        actions: [
                            { action: "filter", icon: faFilter }
                        ],
                        filters: [
                            {
                                id: "lstCategories",
                                type: "list",
                                data: lstCategories,
                                label: "Categoria",
                                multiple: false
                            }
                        ]
                    }
                })
                this.props.dispatch(Loading(false))
            })
            .catch(error => {
                this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false))
            })
    }

    componentWillUnmount() {
    }

    handleEditCategory(categoryId) {
        const cat = this.state.categories.find(f => f.categoryId === categoryId)

        this.setState({ modal: { isOpen: true, categoryId: categoryId, category: cat.category, subCategory: cat.subCategory, categoryName: cat.categoryName } })
    }

    handleFilter(data) {
        const category = parseInt(data[0])

        this.setState({
            table: {
                ...this.state.table, rows: this.state.categories.filter(f => f.category === category || category === -1).map(row => {
                    return {
                        id: row.categoryId,
                        columns: [
                            { column: row.category },
                            { column: row.subCategory },
                            { column: row.categoryName }
                        ],
                        actions: [
                            { column: faEdit, action: () => this.handleEditCategory(row.categoryId) }
                        ]
                    }
                })
            }
        })
    }

    handleSaveCategory() {
        if (this.state.modal.categoryName === "") {
            this.props.dispatch(Toast("Necessário indicar o nome", ToastTypes.Warning, false))
            return
        }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(this.state.modal.categoryName)
        }

        this.props.dispatch(Loading(true))

        fetch("/api/maintenance/categories/" + this.state.modal.categoryId, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível gravar a categoria!", ToastTypes.Danger, true))
                    return
                }

                const categoryId = this.state.modal.categoryId
                let index = this.state.categories.findIndex(f => f.categoryId === categoryId)
                let cat = this.state.categories
                cat[index].categoryName = this.state.modal.categoryName

                index = this.state.table.rows.findIndex(f => f.id === categoryId)
                let gRows = [...this.state.table.rows]
                gRows[index].columns[2].column = this.state.modal.categoryName

                this.setState({
                    modal: { isOpen: false, categoryId: 0, category: 0, subCategory: 0, categoryName: "" },
                    categories: cat,
                    table: { ...this.state.table, rows: gRows }
                })

                this.props.dispatch(Toast("Categoria gravada", ToastTypes.Success, false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível gravar a categoria!", ToastTypes.Danger, true)))
    }

    handleDeleteCategory() {
        if (!window.confirm("Confirma que categoria \"" + this.state.modal.categoryName + "\" é para apagar?"))
            return

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/api/maintenance/categories/" + this.state.modal.categoryId, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível eliminar a categoria!", ToastTypes.Danger, true))
                    return
                }

                const categoryId = this.state.modal.categoryId
                let cat = this.state.categories.filter(f => f.categoryId !== categoryId)
                let gRows = this.state.table.rows.filter(f => f.id !== categoryId)

                this.setState({
                    modal: { isOpen: false, categoryId: 0, category: 0, subCategory: 0, categoryName: "" },
                    categories: cat,
                    table: { ...this.state.table, rows: gRows }
                })

                this.props.dispatch(Toast("Categoria apagada", ToastTypes.Success, false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível gravar a categoria!", ToastTypes.Danger, true)))
    }

    render() {

        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Categoria</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col style={{ textAlign: "left" }}>
                                <Label>Categoria</Label>
                                <Input id="txtCategory" type="text" value={this.state.modal.category} maxLength="10" disabled />
                            </Col>
                            <Col style={{ textAlign: "left" }}>
                                <Label>Sub-Categoria</Label>
                                <Input id="txtSubCategory" type="text" value={this.state.modal.subCategory} maxLength="10" disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "left" }}>
                                <Label>Nome</Label>
                                <Input id="txtCategoryName" type="text" value={this.state.modal.categoryName} maxLength="50"
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, categoryName: e.target.value } })} />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{ display: "inline-block" }}>
                        <Button color="info" onClick={this.handleDeleteCategory} style={{ display: "inline-block" }}><FontAwesomeIcon icon={faTrash} /> Remover</Button>
                        <Button color="primary" onClick={this.handleSaveCategory} style={{ float: "right", display: "inline-block" }}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>)
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.access_token
    }
}

export default connect(mapStateToProps)(Categories)
