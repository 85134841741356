import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHouseDamage } from "@fortawesome/free-solid-svg-icons"

const PageNotFound = (props) => {

    return (
        <div style={{ height: 85 + "vh", display: "table-cell", width: 100 + "vw", textAlign: "center" }} className="align-middle">
            <FontAwesomeIcon icon={faHouseDamage} style={{ fontSize: 30 + "em", color: "rgba(250,180,27, 0.9)" }} />
            <h1 style={{ color: "#607d8b", position: "relative", top: "calc(-1 * 20vh)", fontSize: 5 + "em", fontWeight: "bold" }}>Página não encontrada</h1>
            {document.location.href}
        </div>
    )
}

export default PageNotFound