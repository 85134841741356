import React from 'react'
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import McdTable from "../global/Table"
import { Toast, ToastTypes, Loading, formatDate } from "../global/Utils"
import { faCheck, faEdit, faPlus, faSave } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Row, Col, Input, Label, CustomInput } from 'reactstrap'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

class Sites extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            sites: [],
            stores: [],
            modal: {
                isOpen: false,
                isNew: false,
                siteNumber: "",
                siteName: "",
                storeNumber: "",
                openningYear: "",
                isMcOpCo: false,
                agreementDate: "",
                gotStoreNumber: false,
                olderSiteNumber: ""
            }
        }

        this.handleSaveSite = this.handleSaveSite.bind(this)
    }

    toggle = () => {
        this.setState(prevState => { return { modal: { ...prevState.modal, isOpen: !prevState.modal.isOpen } } })
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/maintenance/sites", requestOptions).then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    sites: json,
                    table: {
                        title: "Sites",
                        columns: {
                            title: ["Site", "Nome", "Ano Abertura", "McOpCo", "Data Venda/Compra", ""],
                            width: [10, 50, 10, 10, 10, 10],
                            align: ["left", "left", "center", "center", "center", "right"],
                            search: [true, true, true, false, false]
                        },
                        rows: json.map(row => {
                            return {
                                id: row.siteNumber,
                                columns: [
                                    { column: row.siteNumber },
                                    { column: row.siteName },
                                    { column: row.openningYear || "" },
                                    { column: row.isMcOpCo ? faCheck : "" },
                                    { column: row.agreementDate || "" },
                                ],
                                actions: [
                                    { column: faEdit, action: () => this.handleEditSite(row.siteNumber) }
                                ]
                            }
                        }),
                        pagination: true,
                        actions: [
                            { action: () => this.handleNewSite(), icon: faPlus, title: "Novo site" }
                        ]
                    }
                })

                this.props.dispatch(Loading(false))
            })
            .catch(error => {
                this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false))
            })

        fetch("/api/capex/stores", requestOptions).then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => this.setState({ stores: json }))
            .catch(() => { })
    }

    handleNewSite() {
        this.setState({
            modal: {
                isOpen: true, isNew: true, siteNumber: "", siteName: "", storeNumber: "", openningYear: "", olderSiteNumber: "",
                isMcOpCo: false, agreementDate: "", gotStoreNumber: false
            }
        })
    }

    handleEditSite(siteNumber) {
        const site = this.state.sites.find(f => f.siteNumber === siteNumber)

        this.setState({
            modal: {
                isOpen: true, isNew: false, siteNumber: siteNumber, siteName: site.siteName, storeNumber: site.storeNumber, openningYear: site.openningYear || "",
                isMcOpCo: site.isMcOpCo, agreementDate: site.agreementDate !== "" ? new Date(site.agreementDate) : "", gotStoreNumber: site.storeNumber !== 0,
                olderSiteNumber: siteNumber
            }
        })
    }

    handleSaveSite() {
        if (this.state.modal.siteNumber === "" || this.state.modal.siteName === "") {
            this.props.dispatch(Toast("Necessário preencher corretamente todos os campos", ToastTypes.Warning, false))
            return
        }

        if ((this.state.modal.isNew && this.state.sites.findIndex(f => f.siteNumber === parseInt(this.state.modal.siteNumber)) !== -1) ||
            (!this.state.modal.isNew && this.state.modal.siteNumber !== this.state.modal.olderSiteNumber && this.state.sites.findIndex(f => f.siteNumber === parseInt(this.state.modal.siteNumber)) !== -1)) {
            this.props.dispatch(Toast("Site já existente", ToastTypes.Warning, false))
            return
        }

        const data = {
            siteName: this.state.modal.siteName,
            storeNumber: this.state.modal.storeNumber || 0,
            openningYear: this.state.modal.openningYear || 0,
            isMcOpco: this.state.modal.isMcOpCo,
            agreementDate: this.state.modal.agreementDate || "",
            siteNumber: this.state.modal.siteNumber,
            olderSiteNumber: this.state.modal.olderSiteNumber || 0
        }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        this.props.dispatch(Loading(true))

        fetch("/api/maintenance/sites", requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível gravar o site!", ToastTypes.Danger, true))
                    return
                }

                let gRows = [...this.state.table.rows]
                let sites = this.state.sites
                const siteNumber = this.state.modal.siteNumber

                if (!this.state.modal.isNew) {
                    let index = sites.findIndex(f => f.siteNumber === this.state.modal.olderSiteNumber)
                    sites[index].siteNumber = parseInt(siteNumber)
                    sites[index].siteName = this.state.modal.siteName
                    sites[index].storeNumber = this.state.modal.storeNumber
                    sites[index].openningYear = this.state.modal.openningYear || ""
                    sites[index].isMcOpCo = this.state.modal.isMcOpCo
                    sites[index].agreementDate = this.state.modal.agreementDate || ""

                    index = this.state.table.rows.findIndex(f => f.id === this.state.modal.olderSiteNumber)
                    gRows[index].id = parseInt(siteNumber)
                    if (this.state.modal.storeNumber !== 0)
                        gRows[index].columns[0].column = parseInt(this.state.modal.storeNumber)
                    gRows[index].columns[0].column = parseInt(siteNumber)
                    gRows[index].columns[1].column = this.state.modal.siteName
                    gRows[index].columns[2].column = this.state.modal.openningYear || ""
                    gRows[index].columns[3].column = this.state.modal.isMcOpCo ? faCheck : ""
                    gRows[index].columns[4].column = this.state.modal.agreementDate !== "" ? formatDate(this.state.modal.agreementDate) : ""
                    gRows[index].actions[0].action = () => this.handleEditSite(siteNumber)
                }
                else {
                    sites.push({ siteNumber: parseInt(siteNumber), siteName: this.state.modal.siteName, storeNumber: 0, openningYear: 0, isMcOpCo: false, agreementDate: "" })

                    const newRow = {
                        id: parseInt(siteNumber),
                        columns: [
                            { column: parseInt(siteNumber) },
                            { column: this.state.modal.siteName },
                            { column: this.state.modal.openningYear || "" },
                            { column: this.state.modal.isMcOpCo ? faCheck : "" },
                            { column: this.state.modal.agreementDate || "" },
                        ],
                        actions: [
                            { column: faEdit, action: () => this.handleEditSite(siteNumber) }
                        ]
                    }

                    gRows = [...gRows, newRow]
                }

                gRows.sort((a, b) => a.columns[0].column - b.columns[0].column)

                this.setState({
                    modal: {
                        isOpen: false, isNew: true, siteNumber: "", siteName: "", storeNumber: "", openningYear: "", isMcOpCo: false,
                        agreementDate: "", gotStoreNumber: false, olderSiteNumber: ""
                    },
                    sites: sites,
                    stores: this.state.stores.filter(f => f.key !== parseInt(this.state.modal.storeNumber)),
                    table: { ...this.state.table, rows: gRows }
                })

                this.props.dispatch(Toast("Site gravado", ToastTypes.Success, false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível gravar o site!", ToastTypes.Danger, true)); console.log(e); })
    }

    render() {

        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Site</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col style={{ textAlign: "left" }}>
                                <Label>Site</Label>
                                <Input id="txtSiteNumber" type="number" value={this.state.modal.siteNumber} min="1" max="9999"
                                    disabled={!this.state.modal.isNew && this.state.modal.gotStoreNumber} onChange={(e) => this.setState({ modal: { ...this.state.modal, siteNumber: e.target.value } })} />
                            </Col>
                            <Col style={{ textAlign: "left" }}>
                                <div style={{ display: this.state.modal.gotStoreNumber || this.state.modal.isNew ? "none" : "" }}>
                                    <Label>Restaurante</Label>
                                    <Input type="select" id="lstStores" value={this.state.modal.storeNumber} onChange={(e) => this.setState({ modal: { ...this.state.modal, storeNumber: e.target.value } })}>
                                        <option value=""></option>
                                        {this.state.stores.map(store => <option key={store.key} value={store.key}>{store.value}</option>)}
                                    </Input>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Nome</Label>
                                <Input id="txtSiteName" type="text" maxLength="100" autoComplete="off" value={this.state.modal.siteName}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, siteName: e.target.value } })} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Ano Abertura</Label>
                                <Input id="txtOpenningYear" type="number" min="1991" max="2100" value={this.state.modal.openningYear} disabled={this.state.modal.isNew}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, openningYear: e.target.value } })} />
                            </Col>
                            <Col>
                                <Label>McOpCo</Label>
                                <CustomInput type="switch" id="chkIsMcOpCo" checked={this.state.modal.isMcOpCo} disabled={this.state.modal.isNew}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, isMcOpCo: e.target.checked } })} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Data venda</Label>
                                <div>
                                    <DatePicker className="form-control" showMonthDropdown showYearDropdown id="txtAgreementDate" isClearable={true} disabled={this.state.modal.isNew}
                                        showPopperArrow={false} dateFormat="yyyy-MM-dd" todayButton="Hoje" selected={this.state.modal.agreementDate} autoComplete="off"
                                        onChange={date => this.setState({ modal: { ...this.state.modal, agreementDate: date } })}>
                                    </DatePicker>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleSaveSite}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>)
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.access_token
    }
}

export default connect(mapStateToProps)(Sites)
