import React from 'react'
import { connect } from "react-redux"

class SignOut extends React.Component {

    render() {

        fetch("/auth/logout")
            .then(response => {
                if (response.status === 302)
                    response.text().then(url => document.location.href = url)

                return null
            })
            .catch(e => console.log("signout-fetch-01", e))

        return (<></>)
    }
}

export default connect()(SignOut)
