import React from 'react'
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import McdTable from "../global/Table"
import { formatDate, Loading, Toast, ToastTypes } from "../global/Utils"
import { faFilter, faEdit, faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Row, Col, Input, Label } from 'reactstrap'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

class ActualPlan extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            modal: {
                isOpen: false,
                isNew: false,
                year: "",
                month: "",
                siteNumber: "",
                departmentId: "",
                categoryId: "",
                projectId: "",
                assetNumber: "",
                description: "",
                purchaseDate: "",
                serviceDate: "",
                voucher: "",
                vendor: "",
                invoice: "",
                assetCost: "",
                index: 0
            },
            capex: [],
            years: [],
            months: [],
            sites: [],
            departments: [],
            categories: [],
            projects: []
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleEditCapex = this.handleEditCapex.bind(this)
        this.handleSaveCapex = this.handleSaveCapex.bind(this)
        this.handleDeleteCapex = this.handleDeleteCapex.bind(this)
    }

    toggle = () => {
        this.setState(prevState => { return { modal: { ...prevState.modal, isOpen: !prevState.modal.isOpen } } })
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        Promise.all([
            fetch("/api/capex/actualyears", requestOptions), fetch("/api/capex/sites", requestOptions), fetch("/api/capex/departments", requestOptions),
            fetch("/api/capex/categories", requestOptions), fetch("/api/capex/projects", requestOptions)
        ]).then(allResponses => {

            if (allResponses.every(f => f.status === 200)) {
                Promise.all([allResponses[0].json(), allResponses[1].json(), allResponses[2].json(), allResponses[3].json(), allResponses[4].json()])
                    .then(allJson => {

                        let years = allJson[0].map((x, index) => { return { value: x, text: x, selected: index === 0 } })
                        let month = (new Date()).getMonth()
                        let months = [
                            { value: 1, text: "janeiro", selected: month === 1 }, { value: 2, text: "fevereiro", selected: month === 2 }, { value: 3, text: "março", selected: month === 3 },
                            { value: 4, text: "abril", selected: month === 4 }, { value: 5, text: "maio", selected: month === 5 }, { value: 6, text: "junho", selected: month === 6 },
                            { value: 7, text: "julho", selected: month === 7 }, { value: 8, text: "agosto", selected: month === 8 }, { value: 9, text: "setembro", selected: month === 9 },
                            { value: 0, text: "outubro", selected: month === 10 }, { value: 11, text: "novembro", selected: month === 11 }, { value: 12, text: "dezembro", selected: month === 0 }
                        ]

                        let sites = allJson[1].map(x => { return { value: x.key, text: x.value, selected: false } })
                        sites.splice(0, 0, { value: -1, text: "Todos", selected: true })

                        const search = parseInt(years.find(f => f.selected).value) * 100 + parseInt(months.find(f => f.selected).value)
                        fetch("/api/maintenance/capex/actual/" + search, requestOptions)
                            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                            .then(json => {
                                this.setState({
                                    capex: json,
                                    years: years.map(x => { return { key: parseInt(x.value), value: x.text } }),
                                    months: months.map(x => { return { key: parseInt(x.value), value: x.text } }),
                                    sites: allJson[1],
                                    departments: allJson[2],
                                    categories: allJson[3],
                                    projects: allJson[4],
                                    modal: { ...this.state.modal, year: years.find(f => f.selected).value, month: months.find(f => f.selected).value },
                                    table: {
                                        title: "Plano Atual",
                                        columns: {
                                            title: ["Site", "Departamento", "Categoria", "Projeto", "Asset", "Descrição", "Valor", ""],
                                            width: [5, 20, 20, 20, 5, 20, 5, 5],
                                            align: ["left", "left", "left", "left", "left", "left", "right", "right"],
                                            search: [true, true, true, true, true, true, true, false]
                                        },
                                        rows: this.buildGridRows(json),
                                        pagination: true,
                                        actions: [
                                            { action: "filter", icon: faFilter },
                                            { action: () => this.handleNewCapex(), icon: faPlus, title: "Nova entrada" }
                                        ],
                                        filters: [
                                            { id: "lstYears", type: "list", data: years, label: "Ano", multiple: false },
                                            { id: "lstMonth", type: "list", data: months, label: "Mês", multiple: false },
                                            { id: "lstSites", type: "list", data: sites, label: "Sites", multiple: false }
                                        ]
                                    }
                                })

                                this.props.dispatch(Loading(false))
                            })

                    })
            }
        })
    }

    buildGridRows(json) {
        return json.map(row => {
            return {
                id: row.siteNumber + "," + getNumber(row.category) + "," + getNumber(row.project) + "," + row.assetNumber,
                columns: [
                    { column: row.siteNumber },
                    { column: row.department },
                    { column: row.category },
                    { column: row.project },
                    { column: row.assetNumber },
                    { column: row.description },
                    { column: parseFloat(row.assetCost).toLocaleString() }
                ],
                actions: [
                    { column: faEdit, action: () => this.handleEditCapex(row.siteNumber + "," + getNumber(row.category) + "," + getNumber(row.project) + "," + row.assetNumber) }
                ]
            }
        })
    }

    handleFilter(data) {
        const year = parseInt(data[0])
        const month = parseInt(data[1])
        const siteNumber = parseInt(data[2])

        let query = ""
        if (siteNumber !== -1)
            query = "?site=" + siteNumber

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/api/maintenance/capex/actual/" + (year * 100 + month) + query, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    table: { ...this.state.table, rows: this.buildGridRows(json) },
                    modal: { ...this.state.modal, year: year, month: month },
                    capex: json
                })

                this.props.dispatch(Loading(false))
            })
            .catch(error => {
                this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false))
            })
    }

    handleNewCapex() {
        this.setState({
            modal: {
                ...this.state.modal,
                isOpen: true, isNew: true,
                siteNumber: "", departmentId: "", categoryId: "", projectId: "", assetNumber: "",
                description: "", purchaseDate: "", serviceDate: "", voucher: "", vendor: "", invoice: "", assetCost: "", index: ""
            }
        })
    }

    handleEditCapex(index) {
        
        const arr = index.split(",")
        const capex = this.state.capex.find(f => f.siteNumber === parseInt(arr[0]) &&
            f.categoryId === parseInt(arr[1]) && f.projectId === parseInt(arr[2]) && f.assetNumber === parseInt(arr[3]))

        this.setState({
            modal: {
                ...this.state.modal,
                isOpen: true, isNew: false,
                siteNumber: capex.siteNumber, departmentId: capex.departmentId, categoryId: capex.categoryId, projectId: capex.projectId, assetNumber: capex.assetNumber,
                description: capex.description, purchaseDate: new Date(capex.purchaseDate), serviceDate: new Date(capex.serviceDate), voucher: capex.voucher, vendor: capex.vendor,
                invoice: capex.invoice, assetCost: capex.assetCost, index: index
            }
        })
    }

    handleSaveCapex() {

        if (this.state.modal.departmentId === "" || this.state.modal.description === "" || this.state.modal.voucher === "" || this.state.modal.vendor === ""
            || this.state.modal.invoice === "" || this.state.modal.assetCost === "" || this.state.modal.purchaseDate === null || this.state.modal.serviceDate === null) {

            this.props.dispatch(Toast("Necessário preencher corretamente todos os campos", ToastTypes.Warning, false))
            return
        }

        if (this.state.modal.isNew && (this.state.modal.year === "" || this.state.modal.month === "" || this.state.modal.siteNumber === "" || this.state.modal.categoryId === ""
            || this.state.modal.projectId === "" || this.state.modal.assetNumber === "")) {

            this.props.dispatch(Toast("Necessário preencher corretamente todos os campos", ToastTypes.Warning, false))
            return
        }

        const data = {
            siteNumber: this.state.modal.siteNumber,
            departmentId: this.state.modal.departmentId,
            categoryId: this.state.modal.categoryId,
            projectId: this.state.modal.projectId,
            assetNumber: this.state.modal.assetNumber,
            description: this.state.modal.description,
            voucher: this.state.modal.voucher,
            vendor: this.state.modal.vendor,
            invoice: this.state.modal.invoice,
            assetCost: this.state.modal.assetCost,
            purchaseDate: this.state.modal.purchaseDate,
            serviceDate: this.state.modal.serviceDate
        }

        const search = parseInt(this.state.modal.year) * 100 + parseInt(this.state.modal.month)

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        this.props.dispatch(Loading(true))

        fetch("/api/maintenance/capex/actual/" + search + "?isnew=" + this.state.modal.isNew, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    if (response.status === 409)
                        this.props.dispatch(Toast("Já existe no plano atual dados para os valores inseridos!", ToastTypes.Danger, true))
                    else
                        this.props.dispatch(Toast("Não foi possível gravar!", ToastTypes.Danger, true))
                    return
                }

                let gRows = [...this.state.table.rows]
                let capex = this.state.capex
                const department = this.state.departments.filter(f => f.key === parseInt(this.state.modal.departmentId)).map(m => m.key + " - " + m.value)[0]

                if (!this.state.modal.isNew) {
                    const arr = this.state.modal.index.split(",")
                    let index = this.state.capex.findIndex(f => f.siteNumber === parseInt(arr[0]) &&
                        f.categoryId === parseInt(arr[1]) && f.projectId === parseInt(arr[2]) && f.assetNumber === parseInt(arr[3]))

                    capex[index].departmentId = this.state.modal.departmentId
                    capex[index].description = this.state.modal.description
                    capex[index].voucher = this.state.modal.voucher
                    capex[index].vendor = this.state.modal.vendor
                    capex[index].invoice = this.state.modal.invoice
                    capex[index].assetCost = this.state.modal.assetCost
                    capex[index].purchaseDate = formatDate(this.state.modal.purchaseDate)
                    capex[index].serviceDate = formatDate(this.state.modal.serviceDate)

                    index = gRows.findIndex(f => f.id === this.state.modal.index)

                    gRows[index].columns[1].column = department
                    gRows[index].columns[5].column = this.state.modal.description
                    gRows[index].columns[6].column = parseFloat(this.state.modal.assetCost).toLocaleString()
                }
                else {
                    const category = this.state.categories.filter(f => f.key === parseInt(this.state.modal.categoryId)).map(m => m.key + " - " + m.value)[0]
                    const project = this.state.projects.filter(f => f.key === parseInt(this.state.modal.projectId)).map(m => m.key + " - " + m.value)[0]

                    capex.push({
                        assetCost: this.state.modal.assetCost,
                        assetNumber: parseInt(this.state.modal.assetNumber),
                        category: category,
                        categoryId: parseInt(this.state.modal.categoryId),
                        department: department,
                        departmentId: parseInt(this.state.modal.departmentId),
                        description: this.state.modal.description,
                        invoice: this.state.modal.invoice,
                        project: project,
                        projectId: parseInt(this.state.modal.projectId),
                        purchaseDate: formatDate(this.state.modal.purchaseDate),
                        serviceDate: formatDate(this.state.modal.serviceDate),
                        siteNumber: parseInt(this.state.modal.siteNumber),
                        vendor: this.state.modal.vendor,
                        voucher: this.state.modal.voucher
                    })

                    const id = this.state.modal.siteNumber + "," + this.state.modal.categoryId + "," + this.state.modal.projectId + "," + this.state.modal.assetNumber

                    const newRow = {
                        id: id,
                        columns: [
                            { column: this.state.modal.siteNumber },
                            { column: department },
                            { column: category },
                            { column: project },
                            { column: this.state.modal.assetNumber },
                            { column: this.state.modal.description },
                            { column: parseFloat(this.state.modal.assetCost).toLocaleString() }
                        ],
                        actions: [
                            {
                                column: faEdit, action: () => this.handleEditCapex(id)
                            }
                        ]
                    }

                    gRows = [...gRows, newRow]
                }

                gRows.sort((a, b) => parseInt(a.columns[0].column) - parseInt(b.columns[0].column)
                    ||
                    parseInt(getNumber(a.columns[1].column)) - parseInt(getNumber(b.columns[1].column))
                    ||
                    parseInt(getNumber(a.columns[2].column)) - parseInt(getNumber(b.columns[2].column))
                    ||
                    parseInt(getNumber(a.columns[3].column)) - parseInt(getNumber(b.columns[3].column))
                    ||
                    parseInt(a.columns[4].column) - parseInt(b.columns[4].column))

                this.setState({
                    modal: {
                        ...this.state.modal,
                        isOpen: false, isNew: true,
                        siteNumber: "", departmentId: "", categoryId: "", projectId: "", assetNumber: "",
                        description: "", purchaseDate: "", serviceDate: "", voucher: "", vendor: "", invoice: "", assetCost: "", index: ""
                    },
                    capex: capex,
                    table: { ...this.state.table, rows: gRows }
                })

                this.props.dispatch(Toast("Capex gravado", ToastTypes.Success, false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível gravar!", ToastTypes.Danger, true)); console.log(e); })
    }

    handleDeleteCapex() {
        if (!window.confirm("Confirma que o atual registo é para remover?"))
            return

        const data = {
            siteNumber: this.state.modal.siteNumber,
            categoryId: this.state.modal.categoryId,
            projectId: this.state.modal.projectId,
            assetNumber: this.state.modal.assetNumber
        }

        const search = parseInt(this.state.modal.year) * 100 + parseInt(this.state.modal.month)

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        this.props.dispatch(Loading(true))

        fetch("/api/maintenance/capex/actual/" + search, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível remover o registo!", ToastTypes.Danger, true))
                    return
                }

                let gRows = this.state.table.rows.filter(f => f.id !== this.state.modal.index)
                let capex = this.state.capex.filter((f, index) => index !== this.state.modal.index)

                this.setState({
                    modal: {
                        ...this.state.modal,
                        isOpen: false, isNew: true,
                        siteNumber: "", departmentId: "", categoryId: "", projectId: "", assetNumber: "",
                        description: "", purchaseDate: "", serviceDate: "", voucher: "", vendor: "", invoice: "", assetCost: "", index: "" },
                    table: { ...this.state.table, rows: gRows },
                    capex: capex
                })

                console.log(this.state)

                this.props.dispatch(Toast("Registo removido", ToastTypes.Success, false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível removido o registo!", ToastTypes.Danger, true)))
    }

    render() {
        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle} className="modal-lg">
                    <ModalHeader toggle={this.toggle}>Plano Atual</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Label>Ano</Label>
                                <Input type="select" id="lstYear" value={this.state.modal.year} disabled={true}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, year: e.target.value } })}>
                                    <option value=""></option>
                                    {this.state.years.map(year => <option key={year.key} value={year.key}>{year.value}</option>)}
                                </Input>
                            </Col>
                            <Col>
                                <Label>Mês</Label>
                                <Input type="select" id="lstMonth" value={this.state.modal.month} disabled={true}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, month: e.target.value } })}>
                                    <option value=""></option>
                                    {this.state.months.map(month => <option key={month.key} value={month.key}>{month.value}</option>)}
                                </Input>
                            </Col>
                            <Col></Col><Col></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Site</Label>
                                <Input type="select" id="lstSites" value={this.state.modal.siteNumber} disabled={!this.state.modal.isNew}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, siteNumber: e.target.value } })}>
                                    <option value=""></option>
                                    {this.state.sites.map(site => <option key={site.key} value={site.key}>{site.key + " - " + site.value}</option>)}
                                </Input>
                            </Col>
                            <Col>
                                <Label>Departamento</Label>
                                <Input type="select" id="lstDepartment" value={this.state.modal.departmentId}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, departmentId: e.target.value } })}>
                                    <option value=""></option>
                                    {this.state.departments.map(department => <option key={department.key} value={department.key}>{department.value}</option>)}
                                </Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Categoria</Label>
                                <Input type="select" id="lstCategory" value={this.state.modal.categoryId} disabled={!this.state.modal.isNew}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, categoryId: e.target.value } })}>
                                    <option value=""></option>
                                    {this.state.categories.map(category => <option key={category.key} value={category.key}>{category.value}</option>)}
                                </Input>
                            </Col>
                            <Col>
                                <Label>Projeto</Label>
                                <Input type="select" id="lstProject" value={this.state.modal.projectId} disabled={!this.state.modal.isNew}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, projectId: e.target.value } })}>
                                    <option value=""></option>
                                    {this.state.projects.map(project => <option key={project.key} value={project.key}>{project.key + " - " + project.value}</option>)}
                                </Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="3">
                                <Label>Asset</Label>
                                <Input id="txtAssetNumber" type="number" min="0" value={this.state.modal.assetNumber} disabled={!this.state.modal.isNew}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, assetNumber: e.target.value } })} />
                            </Col>
                            <Col>
                                <Label>Nome</Label>
                                <Input id="txtDescription" type="text" value={this.state.modal.description} autoComplete="off"
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, description: e.target.value } })} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Data de Compra</Label>
                                <DatePicker className="form-control" showMonthDropdown showYearDropdown id="txtPurchaseDate"
                                    showPopperArrow={false} dateFormat="yyyy-MM-dd" todayButton="Hoje" selected={this.state.modal.purchaseDate} autoComplete="off"
                                    onChange={date => this.setState({ modal: { ...this.state.modal, purchaseDate: date } })}>
                                </DatePicker>
                            </Col>
                            <Col>
                                <Label>Data de Serviço</Label>
                                <DatePicker className="form-control" showMonthDropdown showYearDropdown id="txtServiceDate"
                                    showPopperArrow={false} dateFormat="yyyy-MM-dd" todayButton="Hoje" selected={this.state.modal.serviceDate} autoComplete="off"
                                    onChange={date => this.setState({ modal: { ...this.state.modal, serviceDate: date } })}>
                                </DatePicker>
                            </Col>
                            <Col>
                                <Label>Voucher</Label>
                                <Input id="txtVoucher" type="text" value={this.state.modal.voucher} autoComplete="off"
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, voucher: e.target.value } })} />
                            </Col>
                            <Col>
                                <Label>Vendor</Label>
                                <Input id="txtVendor" type="number" min="0" value={this.state.modal.vendor} autoComplete="off"
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, vendor: e.target.value } })} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <Label>Invoice</Label>
                                <Input id="txtInvoice" type="text" value={this.state.modal.invoice} autoComplete="off"
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, invoice: e.target.value } })} />
                            </Col>
                            <Col></Col>
                            <Col>
                                <Label>Valor</Label>
                                <Input id="txtValue" type="number" min="0" step="0.01" value={this.state.modal.assetCost}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, assetCost: e.target.value } })} />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{ display: "inline-block" }}>
                        <Button color="info" onClick={this.handleDeleteCapex} style={{ display: this.state.modal.isNew ? "none" : "inline-block" }}><FontAwesomeIcon icon={faTrash} /> Remover</Button>
                        <Button color="primary" onClick={this.handleSaveCapex} style={{ float: "right", display: "inline-block" }}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>)
    }
}

const getNumber = val => {
    if (val.indexOf(" - ") === -1)
        return val

    return val.substring(0, val.indexOf(" - "))
}

const mapStateToProps = state => {
    return {
        access_token: state.access_token
    }
}

export default connect(mapStateToProps)(ActualPlan)
