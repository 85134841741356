import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Layout from './components/Layout'
import { connect } from "react-redux"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faNewspaper, faColumns, faPlus, faLayerGroup, faProjectDiagram, faChalkboard, faTools, faClipboardList, faUpload, faBullseye, faDotCircle, faStoreAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import PageNotFound from './components/global/PageNotFound'
import './custom.css'
import { GetParameter } from './components/global/Utils'
import Home from './components/Home'
import Menu from './components/global/Menu'

let access_token = null

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isAuth: false,
            signOut: false,
            menu: [],
            profileId: 0
        }

        this.subItems = []
    }

    componentDidMount() {

        /* Auto Refresh */
        var _this = this;
        setInterval(function () {
            // Get access_token with refresh_token
            fetch("/auth/refresh?gas=1")
                .then(response => {
                    if (response.status === 200)
                        return response.json()

                    // don't have refresh token, need authentication
                    alert("O tempo da sessao expirou! Vai ser necessario nova autenticacao para renovar a sessao.")
                    if (response.status === 302)
                        response.text().then(url => document.location.href = url)
                    return null
                })
                .then(json => {
                    if (json === null)
                        return

                    _this.props.dispatch({ type: "ACCESS_TOKEN", payload: json })
                })
                .catch(e => console.error(e))
        }, 13 * 60 * 1000 /* 13 minutes*/);

        // check if access_token exists
        if (this.props.access_token === null) {

            if (document.location.pathname.toLowerCase().startsWith("/auth")) {
                const code = GetParameter("code")
                const logout = GetParameter("logout")

                if (logout !== null) {
                    // Remove refresh token
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(logout)
                    }

                    fetch("/auth/logout", requestOptions)
                        .then(response => {
                            if (response.status === 200) {
                                this.props.dispatch({ type: "SIGNOUT", payload: true })
                                this.setState({ isAuth: false, signOut: true })

                                // Remove the code from url
                                window.history.replaceState(null, null, "/signout?true")
                            }
                        })
                        .catch(e => console.log("app-fetch-01", e))

                    return
                }

                if (code !== null) {
                    // Get access_token with refresh_token
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(code)
                    }

                    fetch("/auth/authorize", requestOptions)
                        .then(response => {
                            if (response.status === 302) {
                                response.text().then(url => document.location.href = url)
                                return;
                            }

                            return response.json()
                        })
                        .then(json => {
                            if (json === null)
                                return

                            // Remove the code from url
                            window.history.replaceState(null, null, "/")

                            access_token = json
                            this.props.dispatch({ type: "ACCESS_TOKEN", payload: json })
                            this._componentDidMount()
                        })
                        .catch(e => console.log("app-fetch-01", JSON.parse(JSON.stringify(e))))
                }
            }
            else {
                // Get access_token with refresh_token
                fetch("/auth/refresh?gas=1")
                    .then(response => {
                        if (response.status === 200)
                            return response.json()

                        // don't have refresh token, need authentication
                        if (response.status === 302)
                            response.text().then(url => document.location.href = url)
                        return null
                    })
                    .then(json => {
                        if (json === null)
                            return

                        access_token = json
                        this.props.dispatch({ type: "ACCESS_TOKEN", payload: json })
                        this._componentDidMount()
                    })
                    .catch(e => console.log("app-fetch-02", JSON.parse(JSON.stringify(e))))
            }

            return
        }

        // all ok
        access_token = this.props.access_token

        this._componentDidMount()
    }

    _componentDidMount() {

        library.add(faNewspaper, faColumns, faPlus, faLayerGroup, faProjectDiagram, faChalkboard, faTools, faClipboardList, faUpload, faBullseye, faDotCircle, faStoreAlt, faSignOutAlt)

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + access_token, "Content-Type": "application/json" }
        }

        fetch("/auth/authorization", requestOptions)
            .then(response => {
                if (response.status === 200)
                    return response.json();

                if (response.status === 302)
                    response.text().then(url => document.location.href = url)

                throw Error(response.statusText)
            })
            .then(json => {
                if (json === null)
                    return;

                this.props.dispatch({ type: "MENU", payload: { menu: JSON.parse(json.menu), profileId: json.profileId } })
                this.setState({ menu: JSON.parse(json.menu), profileId: json.profileId, isAuth: true })
            })
            .catch(error => console.log(error))
    }

    render() {

        if (this.state.signOut)
            return (<h2 style={{ textAlign: "center" }}>Pode fechar a janela</h2>)

        if (!this.state.isAuth)
            return (<></>)

        return (
            <Layout>
                <Switch>
                    {
                        this.state.menu
                            .filter(filter => {
                                return filter.profile.find(prof => this.state.profileId === prof) !== undefined
                            })
                            .map(item => {
                                if (item.subMenu !== undefined) {
                                    item.subMenu
                                        .filter(filter => {
                                            return filter.profile.find(prof => this.state.profileId === prof) !== undefined
                                        })
                                        .map(subitem => {
                                            if (subitem.subMenu !== undefined) {
                                                subitem.subMenu
                                                    .filter(filter => {
                                                        return filter.profile.find(prof => this.state.profileId === prof) !== undefined
                                                    })
                                                    .map(sub2item => {
                                                        return this.subItems.push(sub2item)
                                                    })
                                            }
                                            return this.subItems.push(subitem)
                                        })
                                }

                                return (
                                    <Route key={item.path} exact path={item.path} component={Menu[item.component]} />
                                )
                            })
                    }
                    {
                        this.subItems.map(item => {
                            return (
                                <Route key={item.path} exact path={item.path} component={Menu[item.component]} />
                            )
                        })
                    }

                    <Route exact path="/" component={Home} />
                    <Route exact path="" component={Home} />
                    <Route component={PageNotFound} />
                </Switch>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.access_token
    }
}

export default connect(mapStateToProps)(App)
