import React from 'react'
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { GetParameter, Loading, Toast, ToastTypes } from "../global/Utils"
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'

class Detail extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                subTotal: [],
                pagination: true,
                actions: []
            }
        }

        this.handleExport = this.handleExport.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        const groupId = GetParameter("groupid")
        const groupName = groupId === "1" ? "Aberturas" : groupId === "2" ? "Reinvestimento" : groupId === "3" ? "Office" : ""

        fetch("/api/reporting/detail" + document.location.search, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    search: json,
                    table: {
                        title: "Resumo - " + groupName,
                        columns: {
                            title: ["Site", "Projetado", "Atual", "Balanço"],
                            width: [70, 10, 10, 10],
                            align: ["left", "right", "right", "right"],
                            search: [true, true, true, true]
                        },
                        rows: this.buildGridRows(json),
                        subTotal: [false, true, true, true],
                        pagination: true,
                        actions: [
                            { action: () => this.handleExport(), icon: faFileCsv }
                        ]
                    }
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
    }

    buildGridRows(json) {
        return json.map((row, index) => {
            return {
                id: index,
                columns: [
                    { column: row.site },
                    { column: parseFloat(row.projection).toLocaleString() },
                    { column: parseFloat(row.actual).toLocaleString() },
                    { column: parseFloat(row.balance).toLocaleString() }
                ]
            }
        })
    }

    handleExport() {

        if (this.state.search.length === 0)
            return

        let csv = "\uFEFF"
        csv += "Site,Projetado,Atual,Balanço\r\n"

        csv += convertToCSV(this.state.search.map(m => "\"" + m.site + "\",\"" + parseFloat(m.projection).toLocaleString() + "\",\"" +
            parseFloat(m.actual).toLocaleString() + "\",\"" + parseFloat(m.balance).toLocaleString() + "\""))

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        var link = document.createElement("a")

        if (link.download !== undefined) {

            link.setAttribute("href", URL.createObjectURL(blob))
            link.setAttribute("download", "capex_reporting_detail.csv")
            link.style.visibility = 'hidden'

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }

    render() {
        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} subTotal={this.state.table.subTotal} />
            </>
        )
    }
}

const convertToCSV = objArray => {
    let str = ''

    objArray.forEach(item => { str += item + "\r\n" })

    return str;
}

const mapStateToProps = state => {
    return {
        access_token: state.access_token
    }
}

export default connect(mapStateToProps)(Detail)
