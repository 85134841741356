import React from 'react'
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import McdTable from "../global/Table"
import { Toast, ToastTypes, Loading } from "../global/Utils"
import { faEdit, faPlus, faSave, faFilter } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Row, Col, Input, Label } from 'reactstrap'

class ProjectionPlan extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            projectionYears: [],
            sites: [],
            projects: [],
            modal: {
                isOpen: false,
                isNew: false,
                capexYear: "",
                siteNumber: "",
                projectId: "",
                projectInitialValue: 0,
                projectValue: 0
            }
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleSaveProjection = this.handleSaveProjection.bind(this)
    }

    toggle = () => {
        this.setState(prevState => { return { modal: { ...prevState.modal, isOpen: !prevState.modal.isOpen } } })
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        Promise.all([fetch("/api/capex/projectionyears", requestOptions), fetch("/api/capex/sites", requestOptions), fetch("/api/capex/projects", requestOptions)])
            .then(allResponses => {
                let projectionYears, sites = [], projects

                if (allResponses[0].status === 200 && allResponses[1].status === 200 && allResponses[2].status === 200) {
                    Promise.all([allResponses[0].json(), allResponses[1].json(), allResponses[2].json()])
                        .then(allJson => {
                            projectionYears = allJson[0].map((x, index) => { return { value: x, text: x, selected: index === 0 } })

                            sites = allJson[1].map(x => { return { value: x.key, text: x.value, selected: false } })
                            sites.splice(0, 0, { value: -1, text: "Todos", selected: true })

                            projects = allJson[2].map(x => { return { value: x.key, text: x.value, selected: false } })
                            projects.splice(0, 0, { value: -1, text: "Todos", selected: true })

                            fetch("/api/maintenance/capex/projection/" + allJson[0][0], requestOptions)
                                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                                .then(json => {
                                    this.setState({
                                        projectionYears: allJson[0],
                                        sites: allJson[1],
                                        projects: allJson[2],
                                        modal: { ...this.state.modal, capexYear: allJson[0][0] },
                                        table: {
                                            title: "Plano Revisto",
                                            columns: {
                                                title: ["Site", "Projeto", "Inicial", "Revisto", ""],
                                                width: [20, 50, 10, 10, 10],
                                                align: ["left", "left", "right", "right", "right"],
                                                search: [true, true, true, true],
                                            },
                                            rows: this.buildGridRows(json),
                                            pagination: true,
                                            actions: [
                                                { action: "filter", icon: faFilter },
                                                { action: () => this.handleNewProjection(), icon: faPlus, title: "Nova entrada" }
                                            ],
                                            filters: [
                                                { id: "lstYears", type: "list", data: projectionYears, label: "Ano", multiple: false },
                                                { id: "lstSites", type: "list", data: sites, label: "Sites", multiple: false },
                                                { id: "lstProjects", type: "list", data: projects, label: "Projetos", multiple: false }
                                            ]
                                        }
                                    })

                                    this.props.dispatch(Loading(false))
                                })
                                .catch(error => {
                                    this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false))
                                })
                        })
                }
            })
    }

    buildGridRows(json) {
        return json.map(row => {
            return {
                id: row.siteProject,
                columns: [
                    { column: row.site },
                    { column: row.project },
                    { column: parseFloat(row.initialValue).toLocaleString() },
                    { column: parseFloat(row.projectedValue).toLocaleString() }
                ],
                actions: [
                    { column: faEdit, action: () => this.handleEditProjection(row.siteProject, row.initialValue, row.projectedValue) }
                ]
            }
        })
    }

    handleNewProjection() {
        this.setState({
            modal: {
                ...this.state.modal, isOpen: true, isNew: true,
                siteNumber: -1, projectId: -1, projectInitialValue: 0, projectValue: 0
            }
        })
    }

    handleEditProjection(key, projectInitialValue, projectValue) {
        const siteNumber = parseInt(key.split(",")[0])
        const projectId = parseInt(key.split(",")[1])

        this.setState({
            modal: {
                ...this.state.modal, isOpen: true, isNew: false,
                siteNumber: siteNumber, projectId: projectId, projectInitialValue: projectInitialValue, projectValue: projectValue
            }
        })
    }

    handleSaveProjection() {

        if (parseInt(this.state.modal.siteNumber) === -1 || parseInt(this.state.modal.projectId) === -1 || this.state.modal.projectValue === "") {
            this.props.dispatch(Toast("Necessário preencher corretamente todos os campos", ToastTypes.Warning, false))
            return
        }

        const data = { site: this.state.modal.siteNumber.toString(), project: this.state.modal.projectId.toString(), projectedValue: parseFloat(this.state.modal.projectValue) }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        this.props.dispatch(Loading(true))

        fetch("/api/maintenance/capex/projection/" + this.state.modal.capexYear + "?isnew=" + this.state.modal.isNew, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    if (response.status === 409)
                        this.props.dispatch(Toast("Já existe no plano revisto valores para o site e projeto inserido!", ToastTypes.Danger, true))
                    else
                        this.props.dispatch(Toast("Não foi possível gravar!", ToastTypes.Danger, true))
                    return
                }

                let gRows = [...this.state.table.rows]
                const siteNumber = this.state.modal.siteNumber
                const projectId = this.state.modal.projectId
                const key = siteNumber + "," + projectId
                const initialValue = this.state.modal.projectInitialValue
                const projectedValue = this.state.modal.projectValue

                if (!this.state.modal.isNew) {
                    let index = this.state.table.rows.findIndex(f => f.id === key)
                    gRows[index].columns[3].column = parseFloat(projectedValue).toLocaleString()
                    gRows[index].actions[0].action = () => this.handleEditProjection(key, initialValue, projectedValue)
                }
                else {
                    const newRow = {
                        id: key,
                        columns: [
                            { column: siteNumber + " - " + this.state.sites.find(f => f.key === parseInt(siteNumber)).value },
                            { column: projectId + " - " + this.state.projects.find(f => f.key === parseInt(projectId)).value },
                            { column: parseFloat(initialValue).toLocaleString() },
                            { column: parseFloat(projectedValue).toLocaleString() }
                        ],
                        actions: [{ column: faEdit, action: () => this.handleEditProjection(key, initialValue, projectedValue) }]
                    }

                    gRows = [...gRows, newRow]
                    gRows.sort((a, b) => parseInt(a.columns[0].column.substring(0, a.columns[0].column.indexOf(" - "))) - parseInt(b.columns[0].column.substring(0, b.columns[0].column.indexOf(" - ")))
                        || 
                        parseInt(a.columns[1].column.substring(0, a.columns[1].column.indexOf(" - "))) - parseInt(b.columns[1].column.substring(0, b.columns[1].column.indexOf(" - "))))
                }

                this.setState({
                    modal: { ...this.state.modal, isOpen: false, isNew: true, siteNumber: "", projectId: "", projectInitialValue: 0, projectValue: 0 },
                    table: { ...this.state.table, rows: gRows }
                })

                this.props.dispatch(Toast("Plano revisto gravado", ToastTypes.Success, false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível gravar!", ToastTypes.Danger, true)); console.log(e); })
    }

    handleFilter(data) {
        const year = parseInt(data[0])
        const siteNumber = parseInt(data[1])
        const projectId = parseInt(data[2])

        let query = ""
        if (siteNumber !== -1)
            query = "?site=" + siteNumber
        if (projectId !== -1)
            query = query !== "" ? "&project=" : "?project=" + projectId

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/api/maintenance/capex/projection/" + year + query, requestOptions)
            .then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({ table: { ...this.state.table, rows: this.buildGridRows(json) }, modal: { ...this.state.modal, capexYear: year } })

                this.props.dispatch(Loading(false))
            })
            .catch(error => {
                this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false))
            })
    }

    render() {
        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Plano Revisto</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Label>Site</Label>
                                <Input type="select" id="lstSites" value={this.state.modal.siteNumber} disabled={!this.state.modal.isNew}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, siteNumber: e.target.value } })}>
                                    <option value=""></option>
                                    {this.state.sites.map(site => <option key={site.key} value={site.key}>{site.key + " - " + site.value}</option>)}
                                </Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Projeto</Label>
                                <Input type="select" id="lstProjects" value={this.state.modal.projectId} disabled={!this.state.modal.isNew}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, projectId: e.target.value } })}>
                                    <option value=""></option>
                                    {this.state.projects.map(project => <option key={project.key} value={project.key}>{project.key + " - " + project.value}</option>)}
                                </Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Valor Inicial</Label>
                                <Input type="number" value={this.state.modal.projectInitialValue} disabled />
                            </Col>
                            <Col>
                                <Label>Novo Valor</Label>
                                <Input id="txtProjectValue" type="number" min="0" value={this.state.modal.projectValue}
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, projectValue: e.target.value } })} />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleSaveProjection}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>)
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.access_token
    }
}

export default connect(mapStateToProps)(ProjectionPlan)
