import React from 'react'
import { connect } from "react-redux"
import { Toast, ToastTypes, Loading } from "../global/Utils"
import { faEdit, faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Row, Col, Input, Label } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import McdTable from "../global/Table"

class Projects extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            modal: {
                isOpen: false,
                isNew: false,
                projectId: undefined,
                projectName: ""
            }
        }

        this.handleSaveProject = this.handleSaveProject.bind(this)
        this.handleDeleteProject = this.handleDeleteProject.bind(this)
    }

    toggle = () => {
        this.setState(prevState => { return { modal: { ...prevState.modal, isOpen: !prevState.modal.isOpen } } })
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/capex/projects", requestOptions).then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    table: {
                        title: "Projetos",
                        columns: {
                            title: ["Numero", "Nome", ""],
                            width: [15, 75, 10],
                            align: ["center", "left", "right"],
                            search: [true, true, false]
                        },
                        rows: json.map(row => {
                            return {
                                id: row.key,
                                columns: [
                                    { column: row.key },
                                    { column: row.value }
                                ],
                                actions: [
                                    { column: faEdit, action: () => this.handleEditProject(row.key, row.value) }
                                ]
                            }
                        }),
                        pagination: true,
                        actions: [
                            { action: () => this.handleNewProject(), icon: faPlus, title: "Novo projecto" }
                        ]
                    }
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => {
                this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false))
            })
    }

    handleNewProject() {
        this.setState({ modal: { isOpen: true, projectId: undefined, projectName: "", isNew: true } })
    }

    handleEditProject(projectId, projectName) {
        this.setState({ modal: { isOpen: true, projectId: projectId, projectName: projectName, isNew: false } })
    }

    handleSaveProject() {
        if (this.state.modal.projectId === "" || this.state.modal.projectName === "") {
            this.props.dispatch(Toast("Necessário preencher corretamente todos os campos", ToastTypes.Warning, false))
            return
        }

        if (this.state.modal.isNew && this.state.table.rows.findIndex(f => f.id === parseInt(this.state.modal.projectId)) !== -1) {
            this.props.dispatch(Toast("Numero de projeto já existente", ToastTypes.Warning, false))
            return
        }

        const data = {
            projectId: this.state.modal.projectId,
            projectName: this.state.modal.projectName
        }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        this.props.dispatch(Loading(true))

        fetch("/api/maintenance/projects", requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível gravar o projeto!", ToastTypes.Danger, true))
                    return
                }

                let gRows = [...this.state.table.rows]

                if (!this.state.modal.isNew) {
                    let index = this.state.table.rows.findIndex(f => f.id === parseInt(data.projectId))
                    gRows[index].columns[1].column = data.projectName
                    gRows[index].actions[0].action = () => this.handleEditProject(data.projectId, data.projectName)
                }
                else {
                    const newRow = {
                        id: parseInt(data.projectId),
                        columns: [
                            { column: data.projectId },
                            { column: data.projectName }
                        ],
                        actions: [
                            { column: faEdit, action: () => this.handleEditProject(data.projectId, data.projectName) }
                        ]
                    }

                    gRows = [...gRows, newRow]
                }

                gRows.sort((a, b) => a.columns[0].column - b.columns[0].column)

                this.setState({
                    modal: { isOpen: false, projectId: undefined, projectName: "", isNew: false },
                    table: { ...this.state.table, rows: gRows }
                })

                this.props.dispatch(Toast("Projeto gravado", ToastTypes.Success, false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível gravar o projeto!", ToastTypes.Danger, true)))
    }

    handleDeleteProject() {
        if (!window.confirm("Confirma que o projeto \"" + this.state.modal.projectName + "\" é para apagar?"))
            return

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/api/maintenance/projects/" + this.state.modal.projectId, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível eliminar o projeto!", ToastTypes.Danger, true))
                    return
                }

                let gRows = this.state.table.rows.filter(f => f.id !== this.state.modal.projectId)

                this.setState({
                    modal: { isOpen: false, projectId: undefined, projectName: "", isNew: false },
                    table: { ...this.state.table, rows: gRows }
                })

                this.props.dispatch(Toast("Projeto apagado", ToastTypes.Success, false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível gravar o projeto!", ToastTypes.Danger, true)))
    }

    render() {

        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} />

                <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Projeto</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="3" style={{ textAlign: "left" }}>
                                <Label>Numero</Label>
                                <Input id="txtProjectId" type="number" value={this.state.modal.projectId === undefined ? "" : this.state.modal.projectId} min="1" max="999"
                                    disabled={!this.state.modal.isNew} onChange={(e) => this.setState({ modal: { ...this.state.modal, projectId: e.target.value } })} />
                            </Col>
                            <Col style={{ textAlign: "left" }}>
                                <Label>Nome</Label>
                                <Input id="txtProjectName" type="text" value={this.state.modal.projectName} maxLength="50" autoComplete="off"
                                    onChange={(e) => this.setState({ modal: { ...this.state.modal, projectName: e.target.value } })} />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{ display: "inline-block" }}>
                        <Button color="info" onClick={this.handleDeleteProject} style={{ display: "inline-block" }}><FontAwesomeIcon icon={faTrash} /> Remover</Button>
                        <Button color="primary" onClick={this.handleSaveProject} style={{ float: "right", display: "inline-block" }}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                    </ModalFooter>
                </Modal>
            </>)
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.access_token
    }
}

export default connect(mapStateToProps)(Projects)
