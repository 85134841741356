import React from 'react'
import { connect } from "react-redux"
import { Col, Container, FormGroup, Input, Label, Row, CustomInput, Collapse } from 'reactstrap'
import { Toast, ToastTypes, Loading } from "../global/Utils"

class Import extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isActual: true,
            initialYear: ""
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleUpload = this.handleUpload.bind(this)
    }

    handleChange(e) {
        this.setState({ isActual: e.target.id === "rdActual" })
    }

    handleUpload(e) {
        const file = e.target.files[0]

        e.target.value = null

        if (!this.state.isActual && (this.state.initialYear === "" || parseInt(this.state.initialYear) < 2000 || parseInt(this.state.initialYear) > 2100)) {
            this.props.dispatch(Toast("É necessário indicar corretamente o ano", ToastTypes.Warning, false))
            return
        }

        if (this.state.isActual && (file.name.split(".").length !== 2 || file.name.toLowerCase().split(".")[1] !== "txt")) {
            this.props.dispatch(Toast("Só é permitido selecionar ficheiros .txt!", ToastTypes.Warning, false))
            return
        }

        if (!this.state.isActual && (file.name.split(".").length !== 2 || file.name.toLowerCase().split(".")[1] !== "csv")) {
            this.props.dispatch(Toast("Só é permitido selecionar ficheiros .csv!", ToastTypes.Warning, false))
            return
        }

        if (file.size === 0) {
            this.props.dispatch(Toast("O ficheiro aparenta não ser válido devido ao seu tamanho!", ToastTypes.Warning, false))
            return
        }

        this.props.dispatch(Loading(true))

        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = (e) => {

            try {
                const lstFile = atob(fileReader.result.split(",")[1]).split("\n")

                if (this.state.isActual)
                    loadActual(lstFile, this)
                else
                    loadProjection(lstFile, this)
            }
            catch (err) {
                console.log(err)
                this.props.dispatch(Toast("Ocorreu um erro ao ler o ficheiro", ToastTypes.Danger, false))
                this.props.dispatch(Loading(false))
            }
        }
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col className="text-center">
                        <FormGroup tag="fieldset">
                            <legend>Tipo de ficheiro</legend>
                            <FormGroup check>
                                <Label check style={{ width: 100 + "px" }}>
                                    <Input type="radio" id="rdActual" name="rgFile" checked={this.state.isActual} onChange={this.handleChange} />{" "}Atual
                                </Label>
                                <Label check>
                                    <Input type="radio" id="rdInitial" name="rgFile" checked={!this.state.isActual} onChange={this.handleChange} />{" "}Inicial
                                </Label>
                            </FormGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Collapse isOpen={!this.state.isActual}>
                    <Row>
                        <Col className="text-center" xs="12" sm={{ size: 6, offset: 3 }}>
                            <FormGroup>
                                <Label htmlFor="txtEfectiveDate">Ano</Label>
                                <Input type="number" id="txtYear" min="2000" max="2100" maxLength="4" minLength="4" style={{ width: 6 + "rem", margin: "0 auto" }}
                                    onChange={e => this.setState({ initialYear: e.target.value })} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Collapse>
                <Row>
                    <Col>
                        <CustomInput type="file" id="exampleCustomFileBrowser" label="Carregamento do ficheiro" onChange={this.handleUpload} />
                    </Col>
                </Row>
            </Container>)
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.access_token
    }
}

export default connect(mapStateToProps)(Import)

const loadActual = (lstFile, _this) => {

    if (lstFile.findIndex(f => f.trim() === "REPORT TITLE:    FIXED ASSET LEDGER DETAIL") === -1) {
        _this.props.dispatch(Toast("O ficheiro introduzido não é válido", ToastTypes.Warning, false))
        return
    }

    let month, year
    let auxMonth, auxYear

    lstFile.every(line => {
        if (line.indexOf("FROM ADD PERIOD") !== -1) {
            month = line.substring(67, 69)
            auxMonth = line.substring(84, 86)

            year = line.substring(70, 72)
            auxYear = line.substring(87, 89)

            if (month !== auxMonth || year !== auxYear) {
                _this.props.dispatch(Toast("Apenas pode importar dados para um mês", ToastTypes.Warning, false))
                return false
            }
            else {
                month = parseInt(month)
                year = parseInt(year) + 2000
            }

            return false
        }

        return true
    })

    let siteNumber, siteName, department, accountNumber, accountName, classNumber, className
    let lstCapex = []

    lstFile.forEach((line, index) => {
        if (line.indexOf("Site:") !== -1 && line.indexOf("Dept") !== -1 && line.indexOf("Account") !== -1 && line.indexOf("Class") !== -1) {
            siteNumber = line.substring(10, 15).trim()
            siteName = line.substring(19, 39).trim()
            department = line.substring(48, 57).trim()
            accountNumber = line.substring(67, 75).trim()
            accountName = line.substring(76, 106).trim()
            classNumber = line.substring(115, 118).trim()
            className = line.substring(119).trim()
        }

        if (line.length < 160 || line.length > 185)
            return;
       

        if (!isNaN(parseInt(line.substring(5, 10).trim())) && !isNaN(parseInt(line.substring(77, 80).trim())) && !isNaN(parseInt(line.substring(101, 104).trim()))) {
            const capex = {
                year: year, month: month, siteNumber: siteNumber, siteName: getStrangeChars(siteName), department: department, accountNumber: accountNumber,
                accountName: getStrangeChars(accountName), classNumber: classNumber, className: getStrangeChars(className), assetNumber: line.substring(1, 13).trim(),
                description1: getStrangeChars(line.substring(15, 45).trim()), description2: getStrangeChars(line.substring(46, 76).trim()), category: line.substring(82, 87).trim(),
                subCategory: line.substring(88, 91).trim(), quantity: line.substring(101, 104).trim(), purchaseDate: getDate(line.substring(105, 113).trim()),
                serviceDate: getDate(line.substring(114, 122).trim()), projectId: line.substring(131, 139).trim(), voucher: line.substring(139, 150).trim(),
                vendor: "", invoice: "", life: "", assetCost: 0.00, accumDeprec: 0.00, netBookValue: 0.00, ytdDepreciation: 0.00, monthlyDepreciation: 0.00
            }

            if (line.length > 163) {
                capex.vendor = line.substring(151, 162).trim() * 1
                capex.invoice = line.substring(163).trim()
            }
            else {
                capex.vendor = line.substring(151).trim() * 1
                capex.invoice = ""
            }

            capex.life = lstFile[index + 1].substring(1, 8).trim()
            capex.assetCost = getDoubleValue(lstFile[index + 1].substring(69, 86).trim(), lstFile[index + 1].substring(86, 88).trim())
            capex.accumDeprec = getDoubleValue(lstFile[index + 1].substring(89, 106).trim(), lstFile[index + 1].substring(106, 108).trim())
            capex.netBookValue = getDoubleValue(lstFile[index + 1].substring(108, 125).trim(), lstFile[index + 1].substring(125, 127).trim())
            capex.ytdDepreciation = getDoubleValue(lstFile[index + 1].substring(128, 145).trim(), lstFile[index + 1].substring(145, 147).trim())
            capex.monthlyDepreciation = getDoubleValue(lstFile[index + 1].substring(147, 159).trim(), lstFile[index + 1].substring(159, 161).trim())

            lstCapex.push(capex)
        }
    })

    if (lstCapex.length === 0) {
        _this.props.dispatch(Toast("Nao há registos a carregar", ToastTypes.Warning, false))
        return false
    }

    const requestOptions = {
        method: "POST",
        headers: { authorization: "bearer " + _this.props.access_token, "Content-Type": "application/json"},
        body: JSON.stringify(lstCapex)
    }

    fetch("/api/maintenance/capex/upload", requestOptions)
        .then(response => {
            if (response.status !== 200)
                _this.props.dispatch(Toast("Não foi possível carregar o ficheiro!", ToastTypes.Danger, true))
            else
                _this.props.dispatch(Toast("Ficheiro carregado!", ToastTypes.Success, false))
        })
        .catch(() => _this.props.dispatch(Toast("Não foi possível carregar o ficheiro!", ToastTypes.Danger, true)))
}

const loadProjection = (lstFile, _this) => {

    let projects, lstCapex = []

    lstFile.forEach((line, index) => {
        if (line.length === 0)
            return

        const columns = line.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
        
        if (index === 0) {
            projects = columns.filter(f => !isNaN(f))
            return
        }

        let capex = { siteNumber: columns[0], siteName: columns[1], projects: [] }

        columns.forEach((col, i) => {
            if (i > 1 && col !== "" && !isNaN(parseInt(col.replace(",", "").replace(/"/g, ""))))
                capex.projects.push({ key: parseInt(projects[i - 2]), value: parseInt(col.replace(",", "").replace(/"/g, ""))})
        })

        lstCapex.push(capex)
    })

    if (lstCapex.length === 0) {
        _this.props.dispatch(Toast("Nao há registos a carregar", ToastTypes.Warning, false))
        return false
    }

    const requestOptions = {
        method: "POST",
        headers: { authorization: "bearer " + _this.props.access_token, "Content-Type": "application/json" },
        body: JSON.stringify(lstCapex)
    }

    fetch("/api/maintenance/capex/projection/" + _this.state.initialYear, requestOptions)
        .then(response => {
            if (response.status !== 200)
                _this.props.dispatch(Toast("Não foi possível carregar o ficheiro!", ToastTypes.Danger, true))
            else
                _this.props.dispatch(Toast("Ficheiro carregado!", ToastTypes.Success, false))
        })
        .catch(() => _this.props.dispatch(Toast("Não foi possível carregar o ficheiro!", ToastTypes.Danger, true)))
}

const getStrangeChars = str => {
    if (str === "")
        return ""

    let aux = str + ""

    aux = aux.replace(/€/g, "Ç")
    aux = aux.replace(/‡¦O/g, "ÇÃO")
    aux = aux.replace(/€¦O/g, "ÇÃO")
    aux = aux.replace(/¦O/g, "ÃO")
    aux = aux.replace(/€ÜE/g, "ÇÕE")

    const searchText = [
        { s: "¦", rU: "Ã", rL: "ã" },
        { s: "¡", rU: "Í", rL: "í" },
        { s: "¢", rU: "Ó", rL: "ó" },
        { s: "ˆ", rU: "Ê", rL: "ê" },
        { s: "'", rU: "´", rL: "´" },
        { s: "¥", rU: "NH", rL: "NH" },
        { s: "£", rU: "ú", rL: "ú" },
        { s: "Ü", rU: "Õ", rL: "Õ" },
        { s: "‡", rU: "ç", rL: "ç" },
        { s: "§", rU: "õ", rL: "õ" },
        { s: "ƒ", rU: "Â", rL: "Â" }
    ]

    searchText.forEach(s => {
        if (aux.indexOf(s.s) !== -1) {

            if (aux.indexOf(s.s) + 1 < aux.length) {
                let c1 = aux[aux.indexOf(s.s) - 1]
                const c2 = aux[aux.indexOf(s.s) + 1]

                if (aux.indexOf(s.s) === 0)
                    c1 = aux[aux.indexOf(s.s)]
                
                if (c1 === c1.toUpperCase() && (c2 === c2.toUpperCase() || c2 === " "))
                    aux = aux.replace(s.s, s.rU)
                else
                    aux = aux.replace(s.s, s.rL)
            }
            else {
                aux = aux.replace(s.s, s.rL)
            }
        }
    })

    aux = aux.replace("3§", "3º")
    aux = aux.replace("1§", "1º")
    aux = aux.replace("g§", "gº")
    aux = aux.replace("N§", "Nº")
    aux = aux.replace("M§", "Nº")
    aux = aux.replace("GR FICA", "GRÁFICA")
    aux = aux.replace(" rea ", "Área ")
    aux = aux.replace(" gueda", "Águeda")
    aux = aux.replace(" GUEDA", "ÁGUEDA")
    aux = aux.replace("ar‚m", "arém")
    aux = aux.replace("f‚r", "fér")
    aux = aux.replace("F b", "Fáb")
    aux = aux.replace("d‚bito", "débito")
    aux = aux.replace("Jos‚", "José")
    aux = aux.replace("Jose", "José")
    aux = aux.replace("R PIDO", "RÁPIDO")
    aux = aux.replace("R PIDA", "RÁPIDA")
    aux = aux.replace("Pal cio", "Palácio")
    aux = aux.replace("PAL CIO", "PALÁCIO")
    aux = aux.replace("M RIO", "MÁRIO")
    aux = aux.replace("R pida", "Rápida")
    aux = aux.replace("", "Ç")
    aux = aux.replace("", "Ç")
    aux = aux.replace("", "É")
    aux = aux.replace("", "ê")
    aux = aux.replace("", "é")
    aux = aux.replace("", "ç")

    return aux
}

const getDate = str => {
    let fDay = str.substring(0, str.indexOf("/"))
    let fMonth = str.substring(str.indexOf("/") + 1, str.lastIndexOf("/"))
    let fYear = str.substring(str.lastIndexOf("/") + 1)

    if (parseInt(fYear) > 90)
        fYear = "19" + fYear
    else
        fYear = "20" + fYear

    if (parseInt(fDay) < 10 && fDay.length === 1)
        fDay = "0" + fDay

    if (parseInt(fMonth) < 10 && fMonth.length === 1)
        fMonth = "0" + fMonth

    return fYear + "-" + fMonth + "-" + fDay
}

const getDoubleValue = (num, sinal) => {
    num = num.replace(",", "")

    if (num === "" || isNaN(num))
        return 0.00

    if (sinal.length >= 1)
        sinal = sinal.substring(0, 1)

    if (sinal === "-")
        return parseFloat(num * -1)

    return parseFloat(num)
}
