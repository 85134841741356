import React from 'react'
import { connect } from "react-redux"
import McdTable from "./global/Table"
import { Loading, Toast, ToastTypes } from "./global/Utils"
import { faFileCsv, faFilter } from '@fortawesome/free-solid-svg-icons'

class Home extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                subTotal: [],
                pagination: true,
                actions: []
            },
            search: [],
            detail: false
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleExport = this.handleExport.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        Promise.all([
            fetch("/api/capex/actualyears", requestOptions),
            fetch("/api/capex/projects", requestOptions),
            fetch("/api/capex/categories", requestOptions),
            fetch("/api/capex/accounts", requestOptions),
            fetch("/api/capex/classes", requestOptions),
            fetch("/api/capex/search/departments", requestOptions),
            fetch("/api/capex/search/filter", requestOptions)
        ])
            .then(allResponses => {
                let years, months, projects, categories, accounts, classes, departments

                if (allResponses[0].status === 200 && allResponses[1].status === 200 && allResponses[2].status === 200 && allResponses[3].status === 200 &&
                    allResponses[4].status === 200 && allResponses[5].status === 200 && allResponses[6].status === 200) {
                    Promise.all([allResponses[0].json(), allResponses[1].json(), allResponses[2].json(), allResponses[3].json(), allResponses[4].json(),
                    allResponses[5].json(), allResponses[6].json()])
                        .then(allJson => {

                            years = allJson[0].map((x, index) => { return { value: x, text: x, selected: index === 0 } })
                            let month = (new Date()).getMonth()
                            months = [
                                { value: 1, text: "janeiro", selected: month === 1 }, { value: 2, text: "fevereiro", selected: month === 2 }, { value: 3, text: "março", selected: month === 3 },
                                { value: 4, text: "abril", selected: month === 4 }, { value: 5, text: "maio", selected: month === 5 }, { value: 6, text: "junho", selected: month === 6 },
                                { value: 7, text: "julho", selected: month === 7 }, { value: 8, text: "agosto", selected: month === 8 }, { value: 9, text: "setembro", selected: month === 9 },
                                { value: 10, text: "outubro", selected: month === 10 }, { value: 11, text: "novembro", selected: month === 11 }, { value: 12, text: "dezembro", selected: month === 0 }
                            ]

                            projects = allJson[1].map(x => { return { value: x.key, text: x.value, selected: true } })
                            categories = allJson[2].map(x => { return { value: x.key, text: x.value, selected: true } })
                            accounts = allJson[3].map(x => { return { value: x.key, text: x.value, selected: true } })
                            classes = allJson[4].map(x => { return { value: x.key, text: x.value, selected: true } })
                            departments = allJson[5].map(x => { return { value: x.key, text: x.value, selected: true, parent: x.parent } })

                            let filters = allJson[6].map(x => { return { value: x.key, text: x.value, selected: true } })

                            const data = {
                                capexYear: years.find(f => f.selected).value,
                                capexStartMonth: months.find(f => f.selected).value,
                                capexEndMonth: months.find(f => f.selected).value,
                                projects: projects.map(m => m.value),
                                categories: categories.map(m => m.value),
                                accounts: accounts.map(m => m.value),
                                classes: classes.map(m => m.value),
                                departments: departments.map(m => m.value),
                                detail: this.state.detail
                            }

                            const requestOptions = {
                                method: "POST",
                                headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
                                body: JSON.stringify(data)
                            }

                            fetch("/api/capex/search", requestOptions)
                                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                                .then(json => {
                                    this.setState({
                                        projects: allJson[1],
                                        categories: allJson[2],
                                        accounts: allJson[3],
                                        classes: allJson[4],
                                        departments: allJson[5],
                                        search: json,
                                        table: {
                                            title: "Consulta",
                                            columns: {
                                                title: ["Site", "Departamento", "Asset", "Categoria", "Descrição", "Data de Serviço", "Projeto", "Valor"],
                                                width: [5, 15, 5, 15, 18, 10, 15, 5],
                                                align: ["left", "left", "left", "left", "left", "left", "left", "right"],
                                                search: [true, true, true, true, true, true, true, true]
                                            },
                                            rows: this.buildGridRows(json),
                                            subTotal: [false, false, false, false, false, false, false, true],
                                            pagination: true,
                                            actions: [
                                                { action: "filter", icon: faFilter },
                                                { action: () => this.handleExport(), icon: faFileCsv }
                                            ],
                                            filters: [
                                                { id: "lstYears", type: "list", data: years, label: "Ano", multiple: false },
                                                { id: "lstMonthsStart", type: "list", data: months, label: "Mês Inicio", multiple: false },
                                                { id: "lstMonthsEnd", type: "list", data: months, label: "Mês Fim", multiple: false },
                                                { id: "chkDetail", type: "switch", data: false, label: "Detalhe" },
                                                { id: "lstProjects", type: "list", data: projects, label: "Projetos", multiple: true },
                                                { id: "lstCategories", type: "list", data: categories, label: "Categorias", multiple: true },
                                                { id: "lstAccounts", type: "list", data: accounts, label: "Account", multiple: true },
                                                { id: "lstClasses", type: "list", data: classes, label: "Class", multiple: true },
                                                { id: "lstDepartments", type: "list", data: departments, label: "Departamento", multiple: true },
                                                filters.length !== 1 ?
                                                    { id: "chkFilters", type: "list", data: filters, label: "Filtro", multiple: true, child_id: "lstDepartments", child_clean: [] } :
                                                    null
                                            ]
                                        }
                                    })

                                    this.props.dispatch(Loading(false))
                                })

                        })
                        .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos2", ToastTypes.Danger, false)))
                }
                else {
                    return allResponses.then(Promise.reject.bind(Promise))
                }
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
    }

    buildGridRows(json, detail) {
        return json.map((row, index) => {
            return {
                id: index,
                columns: detail === true ?
                    [
                        { column: row.site },
                        { column: row.department },
                        { column: row.accountNumber },
                        { column: row.assetNumber },
                        { column: row.category },
                        { column: row.description },
                        { column: row.serviceDate },
                        { column: row.project },
                        { column: row.life },
                        { column: row.voucher },
                        { column: row.vendor },
                        { column: row.invoice },
                        { column: parseFloat(row.assetCost).toLocaleString() },
                        { column: parseFloat(row.monthlyDepreciation).toLocaleString() },
                        { column: parseFloat(row.accumDepreciation).toLocaleString() },
                        { column: parseFloat(row.netBookValue).toLocaleString() }
                    ]
                    :
                    [
                        { column: row.site },
                        { column: row.department },
                        { column: row.assetNumber },
                        { column: row.category },
                        { column: row.description },
                        { column: row.serviceDate },
                        { column: row.project },
                        { column: parseFloat(row.assetCost).toLocaleString() }
                    ]
            }
        })
    }

    handleFilter(data) {

        const query = {
            capexYear: parseInt(data[0]),
            capexStartMonth: parseInt(data[1]),
            capexEndMonth: parseInt(data[2]),
            detail: data[3],
            projects: data[4],
            categories: data[5],
            accounts: data[6],
            classes: data[7],
            departments: data[8]
        }

        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(query)
        }

        this.props.dispatch(Loading(true))

        fetch("/api/capex/search", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    search: json,
                    detail: query.detail,
                    table: {
                        ...this.state.table,
                        rows: this.buildGridRows(json, query.detail),
                        columns: {
                            title: query.detail ?
                                ["Site", "Departamento", "Account", "Asset", "Categoria", "Descrição", "Data Serviço", "Projeto", "Life", "Voucher", "Vendor", "Invoice", "Valor", "Deprec.", "Accum Deprec.", "NBV"]
                                :
                                ["Site", "Departamento", "Asset", "Categoria", "Descrição", "Data de Serviço", "Projeto", "Valor"],
                            width: query.detail ?
                                [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5]
                                :
                                [5, 15, 5, 15, 18, 10, 15, 5],
                            align: query.detail ?
                                ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "right", "right", "right", "right"]
                                :
                                ["left", "left", "left", "left", "left", "left", "left", "right"],
                            search: query.detail ?
                                [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true]
                                :
                                [true, true, true, true, true, true, true, true]
                        }
                    }
                })

                this.props.dispatch(Loading(false))
            })
            .catch(error => {
                this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false))
            })
    }

    handleExport() {

        if (this.state.search.length === 0)
            return

        let csv = "\uFEFF"

        if (this.state.detail) {
            csv += "Site,Departamento,Account,Asset,Categoria,Descrição,Data Serviço,Projeto,Life,Voucher,Vendor,Invoice,Valor,Deprec.,Accum Deprec.,NBV\r\n"
            csv += convertToCSV(this.state.search.map(m => m.site + ",\"" + m.department + "\"," + m.accountNumber + "," + m.assetNumber + ",\"" +
                m.category + "\",\"" + m.description + "\"," + m.serviceDate + ",\"" + m.project + "\"," + m.life + ",\"" + m.voucher + "\"," +
                m.vendor + ",\"" + m.invoice + "\",\"" + parseFloat(m.assetCost).toLocaleString() + "\",\"" + parseFloat(m.monthlyDepreciation).toLocaleString() + "\",\"" +
                parseFloat(m.accumDepreciation).toLocaleString() + "\",\"" + parseFloat(m.netBookValue).toLocaleString() + "\""))
        }
        else {
            csv += "Site,Departamento,Account,Asset,Categoria,Descrição,Data de Serviço,Life,Projeto,Valor\r\n"
            csv += convertToCSV(this.state.search.map(m => m.site + ",\"" + m.department + "\"," + m.accountNumber + "," + m.assetNumber + ",\"" +
                m.category + "\",\"" + m.description + "\"," + m.serviceDate + "," + m.life + ",\"" + m.project + "\",\"" + parseFloat(m.assetCost).toLocaleString() + "\""))
        }

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        var link = document.createElement("a")

        if (link.download !== undefined) {

            link.setAttribute("href", URL.createObjectURL(blob))
            link.setAttribute("download", "capex_export.csv")
            link.style.visibility = 'hidden'

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }

    render() {
        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} subTotal={this.state.table.subTotal} />
            </>
        )
    }
}

const convertToCSV = objArray => {
    let str = ''

    objArray.forEach(item => { str += item + "\r\n" })

    return str;
}

const mapStateToProps = state => {
    return {
        access_token: state.access_token
    }
}

export default connect(mapStateToProps)(Home)
