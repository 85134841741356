import Home from '../Home'
import ActualPlan from '../maintenance/ActualPlan'
import Categories from '../maintenance/Categories'
import Import from '../maintenance/Import'
import ProjectionPlan from '../maintenance/ProjectionPlan'
import Projects from '../maintenance/Projects'
import Sites from '../maintenance/Sites'
import SignOut from '../SignOut'
import Project from '../reporting/Project'
import Resume from '../reporting/Resume'
import Detail from '../reporting/Detail'
import Groups from '../reporting/Groups'
import Openings from '../reporting/Openings'

/*const menu = [
    { path: "/", label: "Home", icon: faNewspaper, enable: true, profile: [1, 101, 102, 103, 104, 105, 106] },
    {
        path: "/reports", label: "Relatórios", icon: faColumns, enable: false, profile: [1, 101, 102, 103, 104, 105], subMenu: [
            { path: "/reports/opennings", label: "Aberturas", icon: faPlus, enable: true, profile: [1] },
            { path: "/reports/groups", label: "Grupo", icon: faLayerGroup, enable: true, profile: [1, 101, 102, 103, 104, 105] },
            { path: "/reports/projects", label: "Projetos", icon: faProjectDiagram, enable: true, profile: [1, 101, 102, 103, 104, 105] },
            { path: "/reports/resume", label: "Resumo", icon: faChalkboard, enable: true, profile: [1, 101, 102, 103, 104, 105] }
        ]
    },
    {
        path: "/maintenance", label: "Manutenção", icon: faTools, enable: false, profile: [1], subMenu: [
            { path: "/maintenance/categories", compoenent: Categories, label: "Categorias", icon: faClipboardList, enable: true, profile: [1] },
            { path: "/maintenance/import", component: Import, label: "Carregamento", icon: faUpload, enable: true, profile: [1] },
            { path: "/maintenance/actualplan", component: ActualPlan, label: "Plano Atual", icon: faBullseye, enable: true, profile: [1] },
            { path: "/maintenance/projectionplan", component: ProjectionPlan, label: "Plano Revisto", icon: faDotCircle, enable: true, profile: [1] },
            { path: "/maintenance/projects", component: Projects, label: "Projetos", icon: faProjectDiagram, enable: true, profile: [1] },
            { path: "/maintenance/restaurants", component: Restaurants, label: "Restaurantes", icon: faStoreAlt, enable: true, profile: [1] }
        ]
    },
    { path: "/signout", component: SignOut, label: "Signout", icon: faSignOutAlt, enable: true, profile: [1, 101, 102, 103, 104, 105, 106] }
]*/

const menu = {
    "Home": Home, "Categories": Categories, "Import": Import, "ActualPlan": ActualPlan, "ProjectionPlan": ProjectionPlan, "Projects": Projects, "Sites": Sites, "SignOut": SignOut,
    "Project": Project, "Resume": Resume, "Detail": Detail, "Groups": Groups, "Openings": Openings
}

export default menu
