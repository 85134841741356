import React from 'react'
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Loading, Toast, ToastTypes } from "../global/Utils"
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'

class Openings extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                subTotal: [],
                pagination: true,
                actions: []
            },
            search: []
        }

        this.handleExport = this.handleExport.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        fetch("/api/reporting/openings", requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    search: json,
                    table: {
                        title: "Aberturas",
                        columns: {
                            title: ["Site", ...json.years],
                            width: [10, ...json.years.map(() => 5)],
                            align: ["left", ...json.years.map(() => "right")],
                            search: [true, ...json.years.map(() => false)]
                        },
                        rows: this.buildGridRows(json),
                        subTotal: [false, ...json.years.map(() => true)],
                        pagination: true,
                        actions: [
                            { action: () => this.handleExport(), icon: faFileCsv }
                        ]
                    }
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
    }

    buildGridRows(json) {
        return json.data.map(row => {
            return {
                id: row.siteNumber,
                columns: [
                    { column: row.siteName },
                    ...json.years.map(year => {
                        let aux = row.total.find(f => f.key === year)

                        if (aux !== undefined)
                            return { column: parseFloat(aux.value).toLocaleString() }
                        else
                            return { column: 0 }
                    })
                ]
            }
        })
    }

    handleExport() {

        let csv = "\uFEFF"
        csv += this.state.table.columns.title + "\r\n"
        csv += convertToCSV(this.state.table.rows.map(m => m.columns.map(c => "\"" + c.column + "\"")))

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        var link = document.createElement("a")

        if (link.download !== undefined) {

            link.setAttribute("href", URL.createObjectURL(blob))
            link.setAttribute("download", "capex_reporting_openings.csv")
            link.style.visibility = 'hidden'

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }

    render() {
        
        return (
            <>
                <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                    actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} urlSearch={this.props.urlSearch}
                    subTotal={this.state.table.subTotal}/>
            </>
        )
    }
}

const convertToCSV = objArray => {
    let str = ''

    objArray.forEach(item => { str += item + "\r\n" })

    return str;
}

const mapStateToProps = state => {
    return { access_token: state.access_token }
}

export default connect(mapStateToProps)(Openings)
