import React from 'react'
import { connect } from "react-redux"
import McdTable from "../global/Table"
import { Loading, Toast, ToastTypes } from "../global/Utils"
import { faFileCsv, faFilter } from '@fortawesome/free-solid-svg-icons'

class Groups extends React.Component {
    constructor(props) {
        super(props)

        const table = {
            columns: {
                title: ["Projeto", "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
                width: [16, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7],
                align: ["left", "right", "right", "right", "right", "right", "right", "right", "right", "right", "right", "right", "right"],
                search: [true, false, false, false, false, false, false, false, false, false, false, false, false]
            },
            rows: [],
            subTotal: [false, true, true, true, true, true, true, true, true, true, true, true, true],
            pagination: false,
            actions: []
        }

        this.state = {
            tableMcOpCo1: { ...table, title: "McOpCo - Totais" },
            tableFranchise1: { ...table, title: "Franchise - Totais" },
            tableMcOpCo2: { ...table, title: "McOpCo - Valores" },
            tableFranchise2: { ...table, title: "Franchise - Valores" },
            tableOffice: { ...table, title: "Office - Valores" }
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleExport = this.handleExport.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(Loading(true))

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        Promise.all([
            fetch("/api/capex/actualyears", requestOptions)
        ])
            .then(allResponses => {
                if (allResponses[0].status === 200) {
                    Promise.all([allResponses[0].json()])
                        .then(allJson => {
                            let years = allJson[0].map((x, index) => { return { value: x, text: x, selected: index === 0 } })

                            fetch("/api/reporting/groups/" + years.find(f => f.selected).value, requestOptions)
                                .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
                                .then(json => {
                                    this.setState({
                                        tableMcOpCo1: {
                                            ...this.state.tableMcOpCo1,
                                            rows: this.buildGridRows(json.filter(f => f.agreementId === 0), true),
                                            actions: [
                                                { action: "filter", icon: faFilter },
                                                { action: () => this.handleExport(), icon: faFileCsv }
                                            ],
                                            filters: [
                                                { id: "lstYears", type: "list", data: years, label: "Ano", multiple: false }
                                            ]
                                        },
                                        tableFranchise1: { ...this.state.tableFranchise1, rows: this.buildGridRows(json.filter(f => f.agreementId === 1), true) },
                                        tableMcOpCo2: { ...this.state.tableMcOpCo2, rows: this.buildGridRows(json.filter(f => f.agreementId === 0), false) },
                                        tableFranchise2: { ...this.state.tableFranchise2, rows: this.buildGridRows(json.filter(f => f.agreementId === 1), false) },
                                        tableOffice: {
                                            ...this.state.tableOffice,
                                            rows: this.buildGridRows(json.filter(f => f.agreementId === -1 && (f.projectId === 91 || f.projectId === 92)), false),
                                            actions: [
                                                this.props.profileId === 104 ? { action: "filter", icon: faFilter } : null,
                                                this.props.profileId === 104 ? { action: () => this.handleExport(), icon: faFileCsv } : null
                                            ],
                                            filters: [
                                                this.props.profileId === 104 ? { id: "lstYears", type: "list", data: years, label: "Ano", multiple: false } : null,
                                            ]
                                        }
                                    })

                                    this.props.dispatch(Loading(false))
                                })
                                .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
                        })
                        .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
                }
            })
            .catch(() => this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)))
    }

    buildGridRows(json, isSite) {
        return json.map((row, index) => {
            return {
                id: index,
                columns: [
                    { column: row.projectName },
                    { column: isSite ? row.sites[0] : parseFloat(row.values[0]).toLocaleString() },
                    { column: isSite ? row.sites[1] : parseFloat(row.values[1]).toLocaleString() },
                    { column: isSite ? row.sites[2] : parseFloat(row.values[2]).toLocaleString() },
                    { column: isSite ? row.sites[3] : parseFloat(row.values[3]).toLocaleString() },
                    { column: isSite ? row.sites[4] : parseFloat(row.values[4]).toLocaleString() },
                    { column: isSite ? row.sites[5] : parseFloat(row.values[5]).toLocaleString() },
                    { column: isSite ? row.sites[6] : parseFloat(row.values[6]).toLocaleString() },
                    { column: isSite ? row.sites[7] : parseFloat(row.values[7]).toLocaleString() },
                    { column: isSite ? row.sites[8] : parseFloat(row.values[8]).toLocaleString() },
                    { column: isSite ? row.sites[9] : parseFloat(row.values[9]).toLocaleString() },
                    { column: isSite ? row.sites[10] : parseFloat(row.values[10]).toLocaleString() },
                    { column: isSite ? row.sites[11] : parseFloat(row.values[11]).toLocaleString() }
                ]
            }
        })
    }

    handleFilter(data) {

        let capexYear = parseInt(data[0])

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/api/reporting/groups/" + capexYear, requestOptions)
            .then(response => { return response.status === 200 ? response.json() : response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    tableMcOpCo1: {
                        ...this.state.tableMcOpCo1,
                        rows: this.buildGridRows(json.filter(f => f.agreementId === 0), true)
                    },
                    tableFranchise1: { ...this.state.tableFranchise1, rows: this.buildGridRows(json.filter(f => f.agreementId === 1), true) },
                    tableMcOpCo2: { ...this.state.tableMcOpCo2, rows: this.buildGridRows(json.filter(f => f.agreementId === 0), false) },
                    tableFranchise2: { ...this.state.tableFranchise2, rows: this.buildGridRows(json.filter(f => f.agreementId === 1), false) },
                    tableOffice: { ...this.state.tableOffice, rows: this.buildGridRows(json.filter(f => f.agreementId === -1 && (f.projectId === 91 || f.projectId === 92)), false) }
                })

                this.props.dispatch(Loading(false))
            })
            .catch(() => {
                this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false))
            })
    }

    handleExport() {

        let csv = "\uFEFF"

        if (this.props.profileId !== 104) {
            csv += "McOpCo - Totais\r\n"
            csv += "Projeto, Janeiro, Fevereiro, Março, Abril, Maio, Junho, Julho, Agosto, Setembro, Outubro, Novembro, Dezembro\r\n"
            csv += convertToCSV(this.state.tableMcOpCo1.rows.map(m => "\"" + m.columns[0].column + "\",\"" + m.columns[1].column + "\",\"" + m.columns[2].column +
                "\",\"" + m.columns[3].column + "\",\"" + m.columns[4].column + "\",\"" + m.columns[5].column + "\",\"" + m.columns[6].column +
                "\",\"" + m.columns[7].column + "\",\"" + m.columns[8].column + "\",\"" + m.columns[9].column + "\",\"" + m.columns[10].column +
                "\",\"" + m.columns[11].column + "\",\"" + m.columns[12].column + "\""))

            csv += "\r\n"
            csv += "Franchise - Totais\r\n"
            csv += "Projeto, Janeiro, Fevereiro, Março, Abril, Maio, Junho, Julho, Agosto, Setembro, Outubro, Novembro, Dezembro\r\n"
            csv += convertToCSV(this.state.tableFranchise1.rows.map(m => "\"" + m.columns[0].column + "\",\"" + m.columns[1].column + "\",\"" + m.columns[2].column +
                "\",\"" + m.columns[3].column + "\",\"" + m.columns[4].column + "\",\"" + m.columns[5].column + "\",\"" + m.columns[6].column +
                "\",\"" + m.columns[7].column + "\",\"" + m.columns[8].column + "\",\"" + m.columns[9].column + "\",\"" + m.columns[10].column +
                "\",\"" + m.columns[11].column + "\",\"" + m.columns[12].column + "\""))

            csv += "\r\n"
            csv += "McOpCo - Valores\r\n"
            csv += "Projeto, Janeiro, Fevereiro, Março, Abril, Maio, Junho, Julho, Agosto, Setembro, Outubro, Novembro, Dezembro\r\n"
            csv += convertToCSV(this.state.tableMcOpCo2.rows.map(m => "\"" + m.columns[0].column + "\",\"" + m.columns[1].column + "\",\"" + m.columns[2].column +
                "\",\"" + m.columns[3].column + "\",\"" + m.columns[4].column + "\",\"" + m.columns[5].column + "\",\"" + m.columns[6].column +
                "\",\"" + m.columns[7].column + "\",\"" + m.columns[8].column + "\",\"" + m.columns[9].column + "\",\"" + m.columns[10].column +
                "\",\"" + m.columns[11].column + "\",\"" + m.columns[12].column + "\""))

            csv += "\r\n"
            csv += "Franchise - Valores\r\n"
            csv += "Projeto, Janeiro, Fevereiro, Março, Abril, Maio, Junho, Julho, Agosto, Setembro, Outubro, Novembro, Dezembro\r\n"
            csv += convertToCSV(this.state.tableFranchise2.rows.map(m => "\"" + m.columns[0].column + "\",\"" + m.columns[1].column + "\",\"" + m.columns[2].column +
                "\",\"" + m.columns[3].column + "\",\"" + m.columns[4].column + "\",\"" + m.columns[5].column + "\",\"" + m.columns[6].column +
                "\",\"" + m.columns[7].column + "\",\"" + m.columns[8].column + "\",\"" + m.columns[9].column + "\",\"" + m.columns[10].column +
                "\",\"" + m.columns[11].column + "\",\"" + m.columns[12].column + "\""))

            csv += "\r\n"
        }

        csv += "Office - Valores\r\n"
        csv += "Projeto, Janeiro, Fevereiro, Março, Abril, Maio, Junho, Julho, Agosto, Setembro, Outubro, Novembro, Dezembro\r\n"
        csv += convertToCSV(this.state.tableOffice.rows.map(m => "\"" + m.columns[0].column + "\",\"" + m.columns[1].column + "\",\"" + m.columns[2].column +
            "\",\"" + m.columns[3].column + "\",\"" + m.columns[4].column + "\",\"" + m.columns[5].column + "\",\"" + m.columns[6].column +
            "\",\"" + m.columns[7].column + "\",\"" + m.columns[8].column + "\",\"" + m.columns[9].column + "\",\"" + m.columns[10].column +
            "\",\"" + m.columns[11].column + "\",\"" + m.columns[12].column + "\""))

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        var link = document.createElement("a")

        if (link.download !== undefined) {

            link.setAttribute("href", URL.createObjectURL(blob))
            link.setAttribute("download", "capex_reporting_groups.csv")
            link.style.visibility = 'hidden'

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }

    render() {

        return (
            <>

                <McdTable title={this.state.tableMcOpCo1.title} columns={this.state.tableMcOpCo1.columns} rows={this.state.tableMcOpCo1.rows} pagination={this.state.tableMcOpCo1.pagination}
                    actions={this.state.tableMcOpCo1.actions} filters={this.state.tableMcOpCo1.filters} handlerFilter={this.handleFilter} subTotal={this.state.tableMcOpCo1.subTotal} />

                {this.state.tableFranchise1.rows.length !== 0 ?
                    <McdTable title={this.state.tableFranchise1.title} columns={this.state.tableFranchise1.columns} rows={this.state.tableFranchise1.rows} pagination={this.state.tableFranchise1.pagination}
                        actions={this.state.tableFranchise1.actions} subTotal={this.state.tableFranchise1.subTotal} />
                    :
                    null
                }
                {this.state.tableMcOpCo2.rows.length !== 0 ?
                    <McdTable title={this.state.tableMcOpCo2.title} columns={this.state.tableMcOpCo2.columns} rows={this.state.tableMcOpCo2.rows} pagination={this.state.tableMcOpCo2.pagination}
                        actions={this.state.tableMcOpCo2.actions} subTotal={this.state.tableMcOpCo2.subTotal} />
                    :
                    null
                }
                {this.state.tableFranchise2.rows.length !== 0 ?
                    <McdTable title={this.state.tableFranchise2.title} columns={this.state.tableFranchise2.columns} rows={this.state.tableFranchise2.rows} pagination={this.state.tableFranchise2.pagination}
                        actions={this.state.tableFranchise2.actions} subTotal={this.state.tableFranchise2.subTotal} />
                    :
                    null
                }
                {this.state.tableOffice.rows.length !== 0 ?
                    <McdTable title={this.state.tableOffice.title} columns={this.state.tableOffice.columns} rows={this.state.tableOffice.rows} pagination={this.state.tableOffice.pagination}
                        actions={this.state.tableOffice.actions} filters={this.state.tableMcOpCo1.filters} handlerFilter={this.handleFilter} subTotal={this.state.tableOffice.subTotal} />
                    :
                    null
                }
            </>
        )
    }
}

const convertToCSV = objArray => {
    let str = ''

    objArray.forEach(item => { str += item + "\r\n" })

    return str;
}

const mapStateToProps = state => {
    return { access_token: state.access_token, profileId: 1 }
}

export default connect(mapStateToProps)(Groups)
